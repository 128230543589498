import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd6.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function SpecialCombination() {
  useMetaTracking("ViewContent", {
    content_name: "Special Combination",
    content_category: "Vegan Menu",
    content_ids: ["special_combination"],
    content_type: "bánh mì",
    value: 8.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Special Combination | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Why settle for one flavor when you can have them all? Savory cold cuts, silky Vietnamese pork meatloaf, and buttery pâté stacked inside a crispy baguette—every bite is a little taste of perfection."
        />
        <meta property="og:title" content="Special Combination | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Why settle for one flavor when you can have them all? Savory cold cuts, silky Vietnamese pork meatloaf, and buttery pâté stacked inside a crispy baguette—every bite is a little taste of perfection."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Special Combination"
          titleSubHead="$8.50 each"
          detailBodyBr="Why settle for one flavor when you can have them all? Savory cold cuts, silky Vietnamese pork meatloaf, and buttery pâté stacked inside a crispy baguette—every bite is a little taste of perfection."
          detailBody3="Contains: wheat, soy, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default SpecialCombination;