import "./App.css";
import About from "./about/about";
import BE from "./Drinks/BE";
import BanhMi from "./BanhMi/BanhMi";
import CC from "./NonVegan/CoconutCroissant";
import Coffee from "./Drinks/Coffee";
import VeganCoffee from "./Drinks/VeganCoffee";
import Contact from "./Contact/contact";
import Home from "./Home/Home";
import Menu from "./Menu/Menu";
import MetaPixel from "./utils/hooks/facebookPixelHook";
import Navbar from "./Components/Navbar/Navbar";
import Products from "./products/Products";
import ThankYouPage from "./Contact/ThankYouPage";
import VeganAC from "./Pastries/VeganAC";
import VeganB from "./VietPastries/VeganB";
import VeganBPC from "./VietPastries/VeganBPC";
import VeganBanhMi from "./VeganBanhMi/VeganBanhMi";
import VeganC from "./Pastries/VeganC";
import VeganCC from "./VeganCC/VeganCC";
import VeganD from "./VietPastries/VeganD";
import VeganFC from "./VeganFC/VeganFC";
import VeganGrilledChicken from "./VeganGC/VeganGrilledChicken";
import VeganGrilledPork from "./VeganGF/VeganGrilledPork";
import VeganHNC from "./Pastries/VeganHNC";
import VeganHNCPP from "./VietPastries/VeganHNCPP";
import VeganLemongrassSteak from "./VeganGF/VeganLemongrassSteak";
import VeganMenu from "./VeganMenu/VeganMenu";
import VeganPP from "./VietPastries/VeganPP";
import VeganPorkMeatLoaf from "./VeganPML/VeganPorkMeatLoaf";
import VeganPateMeatLoaf from "./VeganPML/VeganPateMeatLoaf";
import VeganPorkMeatballs from "./VeganPM/VeganPorkMeatballs";
import VeganProtien from "./VeganProtien/VeganProtien";
import VeganSB from "./VietPastries/VeganSB";
import VeganSE from "./VietPastries/VeganSE";
import VeganSMB from "./VietPastries/VeganSMB";
import VeganShreddedPorkSkin from "./VegannSPS/VeganShreddedPorkSkin";
import VeganSpecialCombo from "./VeganSC/VeganSpecialCombo";
import VietnameseTea from "./VietnameseTea/VietnameseTea";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ColdCutHam from "./BanhMi/ColdCutHam";
import ShreddedChicken from "./BanhMi/ShreddedChicken";
import PatePorkMeatLoaf from "./BanhMi/PatePorkMeatloaf";
import GrilledPork from "./BanhMi/GrilledPork";
import GrilledChicken from "./BanhMi/GrilledChicken";
import SpecialCombination from "./BanhMi/SpecialCombination";
import FriedEgg from "./BanhMi/FriedEgg";
import GrilledBeef from "./BanhMi/GrilledBeef";
import HNC from "./Pastries/HNC";
import Croissant from "./Pastries/Croissant";
import PuffPastry from "./VietPastries/PuffPastry";
import SoftMiniBuns from "./VietPastries/SoftMiniBuns";
import SteamedBun from "./VietPastries/SteamedBun";
import PandanMungBean from "./Drinks/PandanMungBean";
import SamBoLuong from "./Drinks/SamBoLuong";
import VeganSavoryEmpanada from "./VietPastries/VeganSavoryEmpanada";
import VeganGuavaTurnover from "./Pastries/VeganGuavaTurnover";
import VeganAppleTurnover from "./Pastries/VeganAppleTurnover";
import VeganPineappleTurnover from "./Pastries/VeganPineappleTurnover";
import useMetaTracking from "./hooks/useMetaTracking";

function App() {

  useMetaTracking("PageView", {});

  return (
    <HelmetProvider>
      {/* Facebook Pixel hook */}
      <MetaPixel />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/vegan-protein-to-go" element={<VeganProtien />} />
          <Route path="/vietnamese-tea-ceremony" element={<VietnameseTea />} />
          <Route path="/regular-menu" element={<Menu />} />
          <Route path="/vegan-menu" element={<VeganMenu />} />
          <Route path="/vegan-fried-chicken" element={<VeganFC />} />
          <Route path="/vegan-coconut-croissant" element={<VeganCC />} />
          <Route path="/vegan-banh-mi" element={<VeganBanhMi />} />
          <Route path="/banh-mi" element={<BanhMi />} />
          <Route path="/about" element={<About />} />
          <Route path="/lunarnewyear" element={<Products />} />

          {/* Newly added routes */}
          <Route path="/vegan-special-combo" element={<VeganSpecialCombo />} />
          <Route path="/vegan-grilled-pork" element={<VeganGrilledPork />} />
          <Route path="/vegan-grilled-chicken" element={<VeganGrilledChicken />} />
          <Route path="/vegan-lemongrass-steak" element={<VeganLemongrassSteak />} />
          <Route path="/vegan-pork-meatballs" element={<VeganPorkMeatballs />} />
          <Route path="/vegan-pate-meatloaf" element={<VeganPateMeatLoaf />} />
          <Route path="/vegan-pork-meatloaf" element={<VeganPorkMeatLoaf />} />
          <Route path="/vegan-shredded-pork-skin" element={<VeganShreddedPorkSkin />} />

          <Route path="/coconut-croissant" element={<CC />} />

          <Route path="/vegan-croissant" element={<VeganC />} />
          <Route path="/vegan-ham-and-cheese-croissant" element={<VeganHNC />} />
          <Route path="/vegan-almond-croissant" element={<VeganAC />} />

          <Route path="/vietnamese-baguette" element={<VeganB />} />
          <Route path="/vegan-beef-puff-pastry" element={<VeganBPC />} />
          <Route path="/vegan-vietnamese-donut" element={<VeganD />} />
          <Route path="/vegan-ham-and-cheese-puff-pastry" element={<VeganHNCPP />} />
          <Route path="/vegan-puff-pastry" element={<VeganPP />} />
          <Route path="/vegan-steamed-bun" element={<VeganSB />} />
          <Route path="/vegan-sweet-empanada" element={<VeganSE />} />
          <Route path="/vegan-soft-mini-buns" element={<VeganSMB />} />

          <Route path="/vegan-vietnamese-coffee" element={<VeganCoffee />} />
          <Route path="/vietnamese-beauty-elixir" element={<BE />} />
          <Route path="/vietnamese-coffee" element={<Coffee />} />
          <Route path="/cold-cut-ham" element={<ColdCutHam />} />
          <Route path="/shredded-chicken" element={<ShreddedChicken />} />
          <Route path="/pate-pork-meatloaf" element={<PatePorkMeatLoaf />} />
          <Route path="/grilled-pork" element={<GrilledPork />} />
          <Route path="/grilled-chicken" element={<GrilledChicken />} />
          <Route path="/special-combination" element={<SpecialCombination />} />
          <Route path="/fried-egg" element={<FriedEgg  />} />
          <Route path="/grilled-beef" element={<GrilledBeef  />} />
          <Route path="/puff-pastry" element={<PuffPastry />} />

          <Route path="/ham-and-cheese-croissant" element={<HNC />} />
          <Route path="/croissant" element={<Croissant />} />
          <Route path="/soft-mini-buns" element={<SoftMiniBuns />} />
          <Route path="/steamed-bun" element={<SteamedBun />} />
          <Route path="/sam-bo-luong" element={<SamBoLuong />} />
          <Route path="/pandan-mung-bean" element={<PandanMungBean />} />
          <Route path="/vegan-savory-empanada" element={<VeganSavoryEmpanada />} />
          <Route path="/vegan-guava-turnover" element={<VeganGuavaTurnover />} />
          <Route path="/vegan-apple-turnover" element={<VeganAppleTurnover />} />
          <Route path="/vegan-pineapple-turnover" element={<VeganPineappleTurnover />} />

          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
