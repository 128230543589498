import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd9.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function GrilledBeef() {
  useMetaTracking("ViewContent", {
    content_name: "Grilled Beef",
    content_category: "Regular Menu",
    content_ids: ["grilled_beef"],
    content_type: "bánh mì",
    value: 8.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Grilled Beef | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Juicy, flame-grilled beef meets the crunch of a crispy baguette. A perfect mix of smoky, savory, and fresh flavors in every bite."
        />
        <meta property="og:title" content="Grilled Beef | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Juicy, flame-grilled beef meets the crunch of a crispy baguette. A perfect mix of smoky, savory, and fresh flavors in every bite."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Grilled Beef"
          titleSubHead="$8.00 each"
          detailBodyBr="Juicy, flame-grilled beef meets the crunch of a crispy baguette. A perfect mix of smoky, savory, and fresh flavors in every bite."
          detailBody3="Contains: wheat, soy, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default GrilledBeef;