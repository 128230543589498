import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";

export default function ProductCard(props) {
  return (
    <Card
      sx={{
        maxWidth: "333px !important",
        width: "100%",
        background: "transparent",  // Transparent background for entire card
        color: "#fff",
        borderRadius: "30px",
        margin: "5px 2px !important",
        overflow: "hidden",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          image={props.img}
          alt={props.title}
          sx={{
            aspectRatio: "1/1",
            objectFit: "cover",
          }}
        />
        <CardContent
          sx={{
            padding: "15px 0",
            textAlign: "center",
            background: "#00AAE3",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontSize: "16px", fontWeight: "700", margin: "0" }}
          >
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}