import React, { useEffect } from "react";
import { Box, Grid, Typography, Card, CardActionArea } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";
import Footer from "../Components/Footer/Footer";
import MenuCard from "../Components/MenuCards/MenuCards";

// Navigation Icons (matching your Regular Menu)
import Bao from "../Assets/nav_icons/bao.png";
import Cro from "../Assets/nav_icons/croissant.png";
import Dri from "../Assets/nav_icons/drink.png";
import San from "../Assets/nav_icons/sandwich.png";

// Menu Images
import Cardimg1 from "../Assets/Menu/vegan-lemongrass-steak.png";
import Cardimg2 from "../Assets/Menu/MenuCArd10.png";
import Cardimg3 from "../Assets/Menu/MenuCArd13.png";
import Cardimg4 from "../Assets/Menu/MenuCArd12.png";
import Cardimg5 from "../Assets/Menu/MenuCArd14.png";
import Cardimg6 from "../Assets/Menu/MenuCArd11.png";
import Cardimg7 from "../Assets/Menu/MenuCArd7.png";
import Cardimg8 from "../Assets/Menu/MenuCArd17.png";
import Cardimg9 from "../Assets/Menu/MenuCArd18.png";
import Cardimg10 from "../Assets/vegan_coconut_croissant.png";
import Cardimg11 from "../Assets/Menu/MenuCArd20.png";
import Cardimg13 from "../Assets/Menu/MenuCArd24.png";
import Cardimg14 from "../Assets/Menu/MenuCArd28.png";
import Cardimg15 from "../Assets/Menu/MenuCArd25.png";
import Cardimg16 from "../Assets/vegan_steamed_bun.png";
import Cardimg17 from "../Assets/Menu/MenuCArd37.png";
import Cardimg19 from "../Assets/Menu/MenuCArd34.png";
import Cardimg20 from "../Assets/vegan_sweet_empanada.png";
import Cardimg21 from "../Assets/Menu/MenuCArd38.png";
import Cardimg25 from "../Assets/Menu/MenuCArd39.png";
import Cardimg26 from "../Assets/che_duong_nhan.png";
import SamBoLuong from "../Assets/1200x900_vietnamese_herbal_dessert.png";
import VeganSavoryEmpanada from "../Assets/vegan_savory_empanada.png";
import VeganGuavaTurnover from "../Assets/vegan_guava_turnover.png";
import VeganPineappleTurnover from "../Assets/vegan_pineapple_turnover.png";
import VeganAppleTurnover from "../Assets/vegan_apple_turnover.png";

function VeganMenu() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Menu",
    content_category: "Vegan Menu",
    content_ids: ["vegan_menu"],
    content_type: "vegan_menu",
  });

  const navigate = useNavigate();
  const location = useLocation();

  // Scroll to section if there's a hash in the URL
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Vegan Menu | Đông Hưng Viên Bakery</title>
      </Helmet>

      {/* Page Header (spacing matching Regular Menu) */}
      <Box sx={{ background: "#000 !important" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{
                mb: 3,
                lineHeight: 2,
                textAlign: "center",
                px: 2,
                pt: 15,
                color: "#fff",
                fontWeight: 700,
                fontSize: "35px",
                fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                textTransform: "uppercase",
                letterSpacing: "5px",
              }}
            >
              Vegan Menu
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Apple-Style Navigation Bar */}
      <Box sx={{ display: "flex", justifyContent: "center", background: "#000" }}>
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            {/* Vegan BÁNH MÌ */}
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    maxWidth: "333px !important",
                    width: "100%",
                    background: "transparent",
                    color: "#fff",
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "5px 2px !important",
                  }}
                >
                  <CardActionArea
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => {
                      // Scroll to Vegan Bánh Mì Section
                      const section = document.getElementById("vegan-banh-mi-section");
                      if (section) {
                        section.scrollIntoView({ behavior: "smooth", block: "start" });
                      }
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 2,
                      "&:hover": {
                        background: "transparent !important",
                        "& .navText": { fontWeight: "1000" },
                      },
                      "& .MuiCardActionArea-focusHighlight": {
                        backgroundColor: "transparent !important",
                        opacity: 0,
                      },
                    }}
                  >
                    <img
                      src={San}
                      alt="Vegan Bánh Mì"
                      style={{ width: "40px", marginBottom: "8px" }}
                    />
                    <Typography
                      className="navText"
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      VEGAN BÁNH MÌ
                    </Typography>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>

            {/* Vegan PASTRIES */}
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    maxWidth: "333px !important",
                    width: "100%",
                    background: "transparent",
                    color: "#fff",
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "5px 2px !important",
                  }}
                >
                  <CardActionArea
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => {
                      // Scroll to Vegan Pastries Section
                      const section = document.getElementById("VeganPastriesBread");
                      if (section) {
                        section.scrollIntoView({ behavior: "smooth", block: "start" });
                      }
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 2,
                      "&:hover": {
                        background: "transparent !important",
                        "& .navText": { fontWeight: "1000" },
                      },
                      "& .MuiCardActionArea-focusHighlight": {
                        backgroundColor: "transparent !important",
                        opacity: 0,
                      },
                    }}
                  >
                    <img
                      src={Cro}
                      alt="Vegan Pastries"
                      style={{ width: "40px", marginBottom: "8px" }}
                    />
                    <Typography
                      className="navText"
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      VEGAN PASTRIES
                    </Typography>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>

            {/* Vegan Vietnamese PASTRIES */}
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    maxWidth: "333px !important",
                    width: "100%",
                    background: "transparent",
                    color: "#fff",
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "5px 2px !important",
                  }}
                >
                  <CardActionArea
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => {
                      // Scroll to Vegan Vietnamese Pastries Section
                      const section = document.getElementById("vegan-vietnamese-pastries");
                      if (section) {
                        section.scrollIntoView({ behavior: "smooth", block: "start" });
                      }
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 2,
                      "&:hover": {
                        background: "transparent !important",
                        "& .navText": { fontWeight: "1000" },
                      },
                      "& .MuiCardActionArea-focusHighlight": {
                        backgroundColor: "transparent !important",
                        opacity: 0,
                      },
                    }}
                  >
                    <img
                      src={Bao}
                      alt="Vegan Vietnamese Pastries"
                      style={{ width: "40px", marginBottom: "8px" }}
                    />
                    <Typography
                      className="navText"
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      VEGAN VIETNAMESE PASTRIES
                    </Typography>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>

            {/* Vegan DRINKS */}
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    maxWidth: "333px !important",
                    width: "100%",
                    background: "transparent",
                    color: "#fff",
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "5px 2px !important",
                  }}
                >
                  <CardActionArea
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => {
                      // Scroll to Vegan Drinks Section
                      const section = document.getElementById("vegan-drinks");
                      if (section) {
                        section.scrollIntoView({ behavior: "smooth", block: "start" });
                      }
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 2,
                      "&:hover": {
                        background: "transparent !important",
                        "& .navText": { fontWeight: "1000" },
                      },
                      "& .MuiCardActionArea-focusHighlight": {
                        backgroundColor: "transparent !important",
                        opacity: 0,
                      },
                    }}
                  >
                    <img
                      src={Dri}
                      alt="Vegan Drinks"
                      style={{ width: "40px", marginBottom: "8px" }}
                    />
                    <Typography
                      className="navText"
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      VEGAN DRINKS
                    </Typography>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Vegan BÁNH MÌ SECTION */}
      <Box
        id="vegan-banh-mi-section"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: { xs: "center", sm: "center", md: "center", lg: "left" },
                  }}
                >
                  vegan <br /> BÁNH MÌ
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg1}
                      title="Vegan Lemongrass Steak"
                      subTitle="bánh mì BÍT TẾT SẢ CHAY"
                      onAddToCart={() => {
                        navigate("/vegan-lemongrass-steak");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg2}
                      title="Vegan PORK MEATBALLS"
                      subTitle="bánh mì Xiú Mại Chay"
                      onAddToCart={() => {
                        navigate("/vegan-pork-meatballs");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg3}
                      title="Vegan Grilled Pork"
                      subTitle="bánh mì THỊT NƯỚNG CHAY"
                      onAddToCart={() => {
                        navigate("/vegan-grilled-pork");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg4}
                      title="Vegan Grilled Chicken"
                      subTitle="bánh mì Gà NƯỚNG CHAY"
                      onAddToCart={() => {
                        navigate("/vegan-grilled-chicken");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg5}
                      title="Vegan Special Combination"
                      subTitle="bánh mì ĐẶC BIỆT CHAY"
                      onAddToCart={() => {
                        navigate("/vegan-special-combo");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg6}
                      title="Vegan Pâté Meat Loaf"
                      subTitle="bánh mì PÂTÉ CHẢ LUẠ CHAY"
                      onAddToCart={() => {
                        navigate("/vegan-pate-meatloaf");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg7}
                      title="Vegan Shredded Pork Skin"
                      subTitle="bánh mì BÌ CHAY"
                      onAddToCart={() => {
                        navigate("/vegan-shredded-pork-skin");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg8}
                      title="VEGAN FRIED CHICKEN"
                      subTitle="bánh mì GÀ CHIÊN CHAY"
                      onAddToCart={() => {
                        navigate("/vegan-fried-chicken");
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Divider */}
      <Box sx={{ display: "flex", justifyContent: "center", background: "#000", pt: 10 }}>
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ borderTop: "1px solid #fff" }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* VEGAN PASTRIES SECTION */}
      <Box
        id="VeganPastriesBread"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 15,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: { xs: "center", sm: "center", md: "center", lg: "left" },
                  }}
                >
                  VEGAN PASTRIES
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {/* Vegan Pastries Items */}
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg9}
                      title="Vegan Croissant"
                      onAddToCart={() => {
                        navigate("/vegan-croissant");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg10}
                      title="Vegan Coconut Croissant"
                      onAddToCart={() => {
                        navigate("/vegan-coconut-croissant");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg11}
                      title="Vegan Ham & Cheese Croissant"
                      onAddToCart={() => {
                        navigate("/vegan-ham-and-cheese-croissant");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={VeganGuavaTurnover}
                      title="Vegan Guava Turnover"
                      onAddToCart={() => {
                        navigate("/vegan-guava-turnover");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={VeganAppleTurnover}
                      title="Vegan Apple Turnover"
                      onAddToCart={() => {
                        navigate("/vegan-apple-turnover");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={VeganPineappleTurnover}
                      title="Vegan Pineapple Turnover"
                      onAddToCart={() => {
                        navigate("/vegan-pineapple-turnover");
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Divider */}
      <Box sx={{ display: "flex", justifyContent: "center", background: "#000", pt: 10 }}>
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ borderTop: "1px solid #fff" }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* VEGAN VIETNAMESE PASTRIES SECTION */}
      <Box
        id="vegan-vietnamese-pastries"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 15,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: { xs: "center", sm: "center", md: "center", lg: "left" },
                  }}
                >
                  VEGAN Vietnamese <br /> Pastries
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg15}
                      title="Vietnamese Baguette"
                      subTitle="Bánh Mì Baguette"
                      onAddToCart={() => {
                        navigate("/vietnamese-baguette");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg21}
                      title="Vegan Vietnamese donut"
                      subTitle="Bánh Tiêu"
                      onAddToCart={() => {
                        navigate("/vegan-vietnamese-donut");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg16}
                      title="Vegan Vietnamese Steamed Bun"
                      subTitle="Bánh Bao Chay"
                      onAddToCart={() => {
                        navigate("/vegan-steamed-bun");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg17}
                      title="Vegan Puff Pastry"
                      subTitle="Bánh Pâté Chaud Chay"
                      onAddToCart={() => {
                        navigate("/vegan-puff-pastry");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg14}
                      title="VEGAN HAM & CHEESE Puff Pastry"
                      subTitle="Bánh Pâté Chaud Ham & Cheese Chay"
                      onAddToCart={() => {
                        navigate("/vegan-ham-and-cheese-puff-pastry");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg13}
                      title="VEGAN BEEF PUFF PASTRY"
                      subTitle="Bánh Pâté Chaud BÒ Chay"
                      onAddToCart={() => {
                        navigate("/vegan-beef-puff-pastry");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={VeganSavoryEmpanada}
                      title="Vegan Savory Empanada"
                      subTitle="Bánh Quai Vạc Chay"
                      onAddToCart={() => {
                        navigate("/vegan-savory-empanada");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg20}
                      title="vegan sweet empanada"
                      subTitle="Durian, Taro, Mung Bean, Coconut"
                      onAddToCart={() => {
                        navigate("/vegan-sweet-empanada");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg19}
                      title="Vegan Soft Mini Buns"
                      subTitle="Coconut, Taro, Mung Bean, Durian"
                      onAddToCart={() => {
                        navigate("/vegan-soft-mini-buns");
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Divider */}
      <Box sx={{ display: "flex", justifyContent: "center", background: "#000", pt: 10 }}>
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ borderTop: "1px solid #fff" }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* VEGAN DRINKS SECTION */}
      <Box
        id="vegan-drinks"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 15,
          pb: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    pt: 1,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: { xs: "center", sm: "center", md: "center", lg: "left" },
                  }}
                >
                  VEGAN Drinks
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {/* Vegan Drinks Items */}
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg25}
                      title="Vegan Vietnamese COFFEE"
                      subTitle="CÀ PHÊ SỮA 'OAT' ĐÁ"
                      onAddToCart={() => {
                        navigate("/vegan-vietnamese-coffee");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={Cardimg26}
                      title="Vietnamese Beauty elixir"
                      subTitle="Chè Dưỡng Nhan"
                      onAddToCart={() => {
                        navigate("/vietnamese-beauty-elixir");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={SamBoLuong}
                      title="Vietnamese Herbal Dessert"
                      subTitle="Sâm Bổ Lượng"
                      onAddToCart={() => {
                        navigate("/sam-bo-luong");
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </>
  );
}

export default VeganMenu;