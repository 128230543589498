/* global fbq */  // ✅ Declare fbq as a global variable at the top

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

function MetaPixel() {
  useEffect(() => {
    // Ensure fbq is available globally
    if (typeof window.fbq === "undefined") {
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return; 
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

      fbq("init", "650181712676710"); // ✅ Replace with your actual Pixel ID
      fbq("track", "PageView"); // ✅ Tracks all page views
    }
  }, []);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <MetaPixel /> {/* ✅ Initializes the Meta Pixel globally */}
      <App />
    </HelmetProvider>
  </React.StrictMode>
);