import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "./../../Assets/tik.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "20px 0px",
          background: "#292b35",
        }}
      >
        <Box sx={{ maxWidth: "1240px", width: "90%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "capitalize",
                    lineHeight: "1.8rem",
                    marginTop: "10px",
                  }}
                >
                  8536 Westminster Blvd,
                  <br />
                  Westminster CA 92683
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                  }}
                >
                  Phone Number
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "capitalize",
                    lineHeight: "1.8rem",
                    marginTop: "10px",
                  }}
                >
                  <a
                    href="tel:7148914404"
                    style={{ textDecoration: "none", color: "#2ea3f2" }}
                  >
                    (714) 891-4404
                  </a>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                  }}
                >
                  Follow
                </Typography>
                <Box
                  sx={{
                    display: "flex", // now visible on all screen sizes
                    gap: 2,
                    flex: "1",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  {/* Facebook */}
                  <a
                    href="https://www.facebook.com/DongHungVienBakery/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        color: "#00aae3",
                        "&:hover": {
                          backgroundColor: "#f1f1f1",
                        },
                        width: 32,
                        height: 32,
                      }}
                    >
                      <FacebookOutlinedIcon />
                    </IconButton>
                  </a>

                  {/* Instagram */}
                  <a
                    href="https://www.instagram.com/donghungvienbakery/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        color: "#00aae3",
                        "&:hover": {
                          backgroundColor: "#f1f1f1",
                        },
                        width: 32,
                        height: 32,
                      }}
                    >
                      <InstagramIcon />
                    </IconButton>
                  </a>

                  {/* TikTok */}
                  <a
                    href="https://www.tiktok.com/@donghungvienbakery"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        color: "#00aae3",
                        "&:hover": {
                          backgroundColor: "#f1f1f1",
                        },
                        width: 32,
                        height: 32,
                      }}
                    >
                      <img width="18px" src={TikTokIcon} alt="tiktok" />
                    </IconButton>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                }}
              >
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "700",
                      textTransform: "capitalize",
                    }}
                  >
                    About Us
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                }}
              >
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "700",
                      textTransform: "capitalize",
                    }}
                  >
                    Contact
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "20px 0px",
          background: "#1c1d26",
        }}
      >
        <Box sx={{ maxWidth: "1240px", width: "90%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "0px 10px",
                  paddingBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                >
                  © 2025 Đông Hưng Viên Bakery – All Rights Reserved.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
