import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Grid from "@mui/material/Grid";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { styled } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import ReviewPF1 from "../../Assets/review3.png";
import ReviewPF2 from "../../Assets/review4.png";
import ReviewPF3 from "../../Assets/review1.png";
import ReviewPF4 from "../../Assets/review2.png";

const theme = createTheme();

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#00AAE3",
  color: "#fff",
  fontWeight: "bold",
  marginTop: "30px",
  padding: "10px 30px",
  textTransform: "none",
  borderRadius: "30px",
  position: "relative",
  overflow: "hidden",
  transition: "background-color 0.3s ease",

  "&:hover": {
    backgroundColor: "#33BBE8",
  },

  "& .chevron-icon": {
    position: "absolute",
    right: "30px",
    opacity: 0,
    transition: "all 0.5s ease",
  },

  "&:hover .chevron-icon": {
    right: "0px",
    opacity: 1,
  },
}));

const ReviewSection = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const reviews = [
    {
      name: "Martin Lizarraga",
      date: "November 3, 2024",
      review: "Impressed, first time trying a bahn mi",
      profileImage: ReviewPF1,
    },
    {
      name: "LEWDUHKRISSY “Lewduhkrissy”",
      date: "October 8, 2024",
      review:
        "Y’ALL BLEW MY MIND. BEST VEGAN BAHN MI IVE EVER HAD. I CAN’T STOP THINKING ABOUT IT! Im ruined! No other sandwich will ever compare! Coconut croissant was...",
      profileImage: ReviewPF2,
    },
    {
      name: "Bee Keeper",
      date: "October 6, 2024",
      review:
        "Fast and friendly service. Really good pastries. Lots of vegan and vegetarian options. We always get a few extra Bánh Bao Chay to take home to freeze for later",
      profileImage: ReviewPF3,
    },
    {
      name: "katrin davis",
      date: "October 1, 2024",
      review:
        "really good vegan banh-mi and a lot of vegan snacks including some great croissants",
      profileImage: ReviewPF4,
    },
  ];

  return (
    <Box
      sx={{
        color: "#fff",
        padding: "40px 20px",
        textAlign: "center",
        background: "#141414 !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          background: "white",
          maxWidth: "1080px",
          width: "100%",
          py: 3,
        }}
      >
        <Grid container spacing={0}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: "black", fontSize: "24px !important" }}
                variant="h5"
                fontWeight="bold"
              >
                GOOD
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0px",
                  padding: "10px 0px",
                }}
              >
                {[...Array(4)].map((_, i) => (
                  <img
                    src="https://cdn.trustindex.io/assets/platform/Google/star/f.svg"
                    alt="Google"
                    width="30"
                    height="30"
                    loading="lazy"
                    key={i}
                  />
                ))}
                <img
                  src="https://cdn.trustindex.io/assets/platform/Google/star/h.svg"
                  alt="Google"
                  width="30"
                  height="30"
                  loading="lazy"
                />
              </Box>
              <Typography
                sx={{
                  color: "black",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
                variant="body1"
              >
                Based on <strong>226 reviews</strong>
              </Typography>
              <img
                src="https://cdn.trustindex.io/assets/platform/Google/logo.svg"
                alt="Google Logo"
                style={{ width: "100px" }}
              />
            </Box>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Box>
              <Swiper
                id="Review-slider"
                modules={[Navigation]}
                navigation
                spaceBetween={20}
                slidesPerView={isSmallScreen ? 1 : isMediumScreen ? 2 : 2}
                style={{ padding: "10px 40px" }}
              >
                {reviews.map((review, index) => (
                  <SwiperSlide key={index} style={{ display: "flex" }}>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        color: "#000",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        padding: "20px",
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        minHeight: "200px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={review.profileImage}
                            alt={review.name}
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{
                                margin: 0,
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              {review.name}
                            </Typography>
                            <Typography
                              sx={{
                                margin: 0,
                                color: "#888",
                                fontSize: "11px",
                                fontWeight: "400",
                              }}
                            >
                              {review.date}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png"
                            alt={review.name}
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: "0px",
                          padding: "10px 0px",
                        }}
                      >
                        {[...Array(5)].map((_, i) => (
                          <img
                            src="https://cdn.trustindex.io/assets/platform/Google/star/f.svg"
                            alt="Google"
                            width="17"
                            height="17"
                            loading="lazy"
                            key={i}
                          />
                        ))}
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2Nobjvy6foZdgMky9EWBw3YoM6IH43trxBQ&s"
                          alt="Google"
                          width="15"
                          height="15"
                          loading="lazy"
                          style={{ marginLeft: "10px" }}
                        />
                      </Box>
                      <Typography
                        sx={{ fontSize: "15px !important", marginTop: "0px" }}
                        mt={2}
                      >
                        {review.review}
                      </Typography>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Link to="https://g.page/r/CVBxrRaQf5rCEAE/review">
        <StyledButton sx={{ fontSize: "16px" }}>
          Leave A Google Review
          <ChevronRightIcon className="chevron-icon" />
        </StyledButton>
      </Link>
    </Box>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <ReviewSection />
  </ThemeProvider>
);

export default App;
