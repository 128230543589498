import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/Menu/MenuCArd27.png";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function PuffPastry() {
  useMetaTracking("ViewContent", {
    content_name: "Puff Pastry (Pâté Chaud)",
    content_category: "Regular Menu",
    content_ids: ["puff_pastry"],
    content_type: "vietnamese_pastries",
    value: 3.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Puff Pastry (Pâté Chaud) | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Flaky, golden puff pastry filled with savory, seasoned pork or chicken. Light, crispy, and packed with rich, comforting flavors—perfect as a snack or quick bite on the go."
        />
        <meta
          property="og:title"
          content="Puff Pastry (Pâté Chaud) | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Flaky, golden puff pastry filled with savory, seasoned pork or chicken. Light, crispy, and packed with rich, comforting flavors—perfect as a snack or quick bite on the go."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="PUFF PASTRY (PÂTÉ CHAUD)"
          titleSubHead="$3.00 each"
          detailBody="Flaky, golden Vietnamese puff pastry filled with savory, seasoned pork or chicken. Light, crispy, and packed with rich, comforting flavors—perfect as a snack or quick bite on the go."
          detailBodyBr="Contains: wheat, egg, soy"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default PuffPastry;