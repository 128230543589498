import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/Menu/MenuCArd21.png";
import { Helmet } from "react-helmet";
import useMetaTracking from "../hooks/useMetaTracking"; // Import the hook

function VeganAC() {
  // Use the tracking hook for ViewContent event
  useMetaTracking("ViewContent", {
    content_name: "Vegan Almond Croissant",
    content_category: "Vegan Menu",
    content_ids: ["vegan_almond_croissant"],
    content_type: "vegan_pastries",
    value: 3.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Almond Croissant | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Topped with sliced almonds, each bite offers the perfect balance of crispiness and rich, nutty flavor. This vegan-friendly treat is both indulgent and satisfying."
        />
        <meta property="og:title" content="Vegan Almond Croissant | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Topped with sliced almonds, each bite offers the perfect balance of crispiness and rich, nutty flavor. This vegan-friendly treat is both indulgent and satisfying."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>

      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Almond Croissant"
          titleSubHead="$3.00 each"
          detailBody="Topped with sliced almonds, each bite offers the perfect balance of crispiness and rich, nutty flavor. This vegan-friendly treat is both indulgent and satisfying."
          detailBodyBr="Contains: wheat, almond, sesame"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganAC;
