import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/vietnamese_coffee.png";
import useMetaTracking from "../hooks/useMetaTracking";

function Coffee() {
  useMetaTracking("ViewContent", {
    content_name: "Vietnamese Coffee",
    content_category: "Regular Menu",
    content_ids: ["vietnamese_coffee"],
    content_type: "drinks",
    value: 4.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vietnamese Coffee | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Rich, bold Vietnamese coffee meets creamy, sweet condensed milk—poured over ice for the ultimate smooth and refreshing pick-me-up."
        />
        <meta property="og:title" content="Vietnamese Coffee | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Rich, bold Vietnamese coffee meets creamy, sweet condensed milk—poured over ice for the ultimate smooth and refreshing pick-me-up."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="VIETNAMESE COFFEE"
          titleSubHead="$4.50 each"
          detailBody="Rich, bold Vietnamese coffee meets creamy, sweet condensed milk—poured over ice for the ultimate smooth and refreshing pick-me-up."
          detailBodyBr="Contains: dairy"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default Coffee;