import MenuCArd1 from "./../Assets/Menu/MenuCArd1.png";
import MenuCArd10 from "./../Assets/Menu/MenuCArd10.png";
import MenuCArd11 from "./../Assets/Menu/MenuCArd11.png";
import MenuCArd12 from "./../Assets/Menu/MenuCArd12.png";
import MenuCArd13 from "./../Assets/Menu/MenuCArd13.png";
import MenuCArd14 from "./../Assets/Menu/MenuCArd14.png";
import MenuCArd15 from "./../Assets/Menu/MenuCArd15.png";
import MenuCArd17 from "./../Assets/Menu/MenuCArd17.png";
import MenuCArd18 from "./../Assets/Menu/MenuCArd18.png";
import MenuCArd19 from "./../Assets/Menu/MenuCArd19.png";
import MenuCArd2 from "./../Assets/Menu/MenuCArd2.png";
import MenuCArd20 from "./../Assets/Menu/MenuCArd20.png";
import MenuCArd21 from "./../Assets/Menu/MenuCArd21.png";
import MenuCArd22 from "./../Assets/Menu/MenuCArd22.png";
import MenuCArd23 from "./../Assets/Menu/MenuCArd23.png";
import MenuCArd24 from "./../Assets/Menu/MenuCArd24.png";
import MenuCArd25 from "./../Assets/Menu/MenuCArd25.png";
import MenuCArd26 from "./../Assets/Menu/MenuCArd26.png";
import MenuCArd27 from "./../Assets/Menu/MenuCArd27.png";
import MenuCArd28 from "./../Assets/Menu/MenuCArd28.png";
import MenuCArd29 from "./../Assets/Menu/MenuCArd29.png";
import MenuCArd3 from "./../Assets/Menu/MenuCArd3.png";
import MenuCArd30 from "./../Assets/Menu/MenuCArd30.png";
import MenuCArd31 from "./../Assets/Menu/MenuCArd31.png";
import MenuCArd32 from "./../Assets/Menu/MenuCArd32.png";
import MenuCArd33 from "./../Assets/Menu/MenuCArd33.png";
import MenuCArd34 from "./../Assets/Menu/MenuCArd34.png";
import MenuCArd35 from "./../Assets/Menu/MenuCArd35.png";
import MenuCArd36 from "./../Assets/Menu/MenuCArd36.png";
import MenuCArd4 from "./../Assets/Menu/MenuCArd4.png";
import MenuCArd5 from "./../Assets/Menu/MenuCArd5.png";
import MenuCArd6 from "./../Assets/Menu/MenuCArd6.png";
// Removed: import MenuCArd7 from "./../Assets/Menu/MenuCArd7.png";
import MenuCArd8 from "./../Assets/Menu/MenuCArd8.png";
import MenuCArd9 from "./../Assets/Menu/MenuCArd9.png";

import productImg1 from "../Assets/img/1.jpg";
import productImg2 from "../Assets/img/2.png";
import productImg3 from "../Assets/img/3.jpeg";
import productImg4 from "../Assets/img/4.jpg";
import productImg5 from "../Assets/img/5.jpeg";

export const products = [
  {
    id: 1,
    description: 'Sweet preserved lotus seeds',
    name: 'CANDIED LOTUS SEEDS',
    image: productImg1,
    BagPrice: '$12',
    BoxPrice: '$25',
    varityPrice: '',
    rating: 4.5,
  },
  {
    id: 2,
    description:
      'CANDIED COCONUT STRINGS (Flavors include: original, pandan, durian, strawberry)',
    name: 'CANDIED COCONUT STRINGS',
    image: productImg2,
    BagPrice: '$12',
    BoxPrice: '$24',
    varityPrice: '',
    rating: 4.8,
  },
  {
    id: 3,
    description: 'Hard candy bars made with peanuts',
    name: 'VIETNAMESE PEANUT CANDY',
    image: productImg4,
    BagPrice: '$10',
    BoxPrice: '$20',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 4,
    description: 'Thin, crispy peanut brittle',
    name: 'VIETNAMESE PEANUT BRITTLE',
    image: productImg5,
    BagPrice: '',
    BoxPrice: '$20',
    varityPrice: '',
    rating: 4.8,
  },
  {
    id: 5,
    description: 'Sweet & savory dried beef',
    name: 'VIETNAMESE BEEF JERKY',
    image: productImg1,
    BagPrice: '',
    BoxPrice: '$20',
    varityPrice: '',
    rating: 4.5,
  },
  {
    id: 6,
    description: 'Vegan sweet & savory beef jerky',
    name: 'VEGAN VIETNAMESE BEEF JERKY',
    image: productImg2,
    BagPrice: '',
    BoxPrice: '$40',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 7,
    description: 'Sweet-tart candied soursop',
    name: 'CANDIED SOURSOP',
    image: productImg3,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 8,
    description: 'Mix of candied soursop & tamarind',
    name: 'CANDIED SOURSOP & CANDIED TAMARIND',
    image: productImg4,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 9,
    description: 'Spicy candied tamarind',
    name: 'SPICY DRIED TAMARIND',
    image: productImg5,
    BagPrice: '$12',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 10,
    description: 'Crystallized winter melon strips',
    name: 'CANDIED WINTER MELON',
    image: productImg2,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 11,
    description: 'Sweet-spicy lemon & ginger candy',
    name: 'LEMON GINGER CANDY',
    image: productImg1,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 12,
    description: 'Mix of lemon ginger & kumquat-ginger candies',
    name: 'LEMON GINGER CANDY & KUMQUAT GINGER CANDY',
    image: productImg3,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 13,
    description: 'Sweet-spicy citrus candy with ginger',
    name: 'KUMQUAT GINGER CANDY',
    image: productImg4,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 14,
    description: 'Preserved water chestnuts with crunchy texture',
    name: 'CANDIED WATER CHESTNUT',
    image: productImg5,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 15,
    description: 'Chewy fruit candy with sweet-sour taste',
    name: 'CANDIED TAMARIND',
    image: productImg1,
    BagPrice: '$12',
    BoxPrice: '$24',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 16,
    description: 'Vietnamese fruit jelly candies',
    name: 'VIETNAMESE JELLY CANDY',
    image: productImg2,
    BagPrice: '$10',
    BoxPrice: '',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 17,
    description: 'Sweet-savory dried fruit with ginger kick',
    name: 'SALTED DRIED APRICOT',
    image: productImg3,
    BagPrice: '$11',
    BoxPrice: '$22',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 18,
    description: 'Sugar-free sweet & savory dried fruit with ginger kick',
    name: 'SALTED DRIED APRICOT (NO SUGAR)',
    image: productImg4,
    BagPrice: '$13',
    BoxPrice: '$26',
    varityPrice: '',
    rating: 5,
  },
  {
    id: 19,
    description: 'Assorted soft mini cakes without eggs',
    name: '9-PIECE VARIETY SOFT MINI CAKE BOX (NO EGGS)',
    image: productImg5,
    BagPrice: '',
    BoxPrice: '',
    varityPrice: '$45',
    rating: 5,
  },
  {
    id: 20,
    description: 'Assorted soft mini cakes with eggs',
    name: '9-PIECE VARIETY SOFT MINI CAKE BOX (WITH EGGS)',
    image: productImg1,
    BagPrice: '',
    BoxPrice: '',
    varityPrice: '$50',
    rating: 5,
  },
  {
    id: 21,
    description: 'Variety box of assorted candied fruits',
    name: 'ASSORTED CANDIED FRUIT BOX',
    image: productImg2,
    BagPrice: '',
    BoxPrice: '',
    varityPrice: '$45-$60',
    rating: 5,
  },
  {
    id: 22,
    description: 'Crunchy brittle with peanuts & sesame',
    name: 'VIETNAMESE PEANUT & SESAME BRITTLE',
    image: productImg3,
    BagPrice: '$10',
    BoxPrice: '',
    varityPrice: '',
    rating: 5,
  },
];

export const menuItems = [
  { 
    img: MenuCArd1, 
    title: 'Cold Cut Ham', 
    subTitle: 'Thịt Nguội', 
    link: "/cold-cut-ham" 
  },
  { 
    img: MenuCArd2, 
    title: 'Shredded Chicken', 
    subTitle: 'Gà Jambon', 
    link: "/shredded-chicken" 
  },
  { 
    img: MenuCArd3, 
    title: 'Pate Pork Meat Loaf', 
    subTitle: 'Pâté Chả Luạ', 
    link: "/pate-pork-meatloaf" 
  },
  { 
    img: MenuCArd4, 
    title: 'Grilled Pork', 
    subTitle: 'Thịt Nướng', 
    link: "/grilled-pork" 
  },
  { 
    img: MenuCArd5, 
    title: 'Grilled Chicken', 
    subTitle: 'Gà Nướng', 
    link: "/grilled-chicken" 
  },
  { 
    img: MenuCArd6, 
    title: 'Special Combination', 
    subTitle: 'Đặc Biệt', 
    link: "/special-combination" 
  },
  { 
    img: MenuCArd8, 
    title: 'Fried Egg', 
    subTitle: 'Trứng Ốp La', 
    link: "/fried-egg" 
  },
  { 
    img: MenuCArd9, 
    title: 'Grilled Beef', 
    subTitle: 'Bò Nướng', 
    link: "/grilled-beef" 
  },
];

export const BanhMiItems = [
  { 
    img: MenuCArd1, 
    title: 'Cold Cut Ham - $7.50', 
    subTitle: 'Thịt Nguội', 
    link: "/cold-cut-ham"  
  },
  { 
    img: MenuCArd2, 
    title: 'Shredded Chicken - $8.00', 
    subTitle: 'Gà Jambon', 
    link: "/shredded-chicken"  
  },
  { 
    img: MenuCArd3, 
    title: 'Pate Pork Meat Loaf - $7.50', 
    subTitle: 'Pâté Chả Luạ', 
    link: "/pate-pork-meatloaf"  
  },
  { 
    img: MenuCArd4, 
    title: 'Grilled Pork - $8.00', 
    subTitle: 'Thịt Nướng', 
    link: "/grilled-pork"  
  },
  { 
    img: MenuCArd5, 
    title: 'Grilled Chicken - $8.00', 
    subTitle: 'Gà Nướng', 
    link: "/grilled-chicken"  
  },
  { 
    img: MenuCArd6, 
    title: 'Special Combination - $8.50', 
    subTitle: 'Đặc Biệt', 
    link: "/special-combination" 
  },
  { 
    img: MenuCArd8, 
    title: 'Fried Egg - $7.50', 
    subTitle: 'Trứng Ốp La', 
    link: "/fried-egg"  
  },
  { 
    img: MenuCArd9, 
    title: 'Grilled Beef - $8.00', 
    subTitle: 'Bò Nướng', 
    link: "/grilled-beef"  
  },
];
