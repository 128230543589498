import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Rating,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';

const ProductCard = ({ product }) => {
  return (
    <Card
      sx={{
        height: 400,
        paddingBottom: 2,
        margin: 0,
        cursor: 'pointer',
        boxShadow: 'none',
        borderBottom: '1px solid #ccc',
        borderRight: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@media (max-width:600px)': {
          border: '1px solid #ccc',
        },
      }}
    >
      <CardMedia
        component="img"
        height="180"
        image={product.image}
        alt={product.name}
        style={{ objectFit: 'contain', marginTop: '10px' }}
        loading="lazy"
      />
      <CardContent>
        <Typography
          variant="body1"
          component="div"
          style={{
            fontSize: '17px',
          }}
        >
          {product.name}
          <Box sx={{ display: 'none !important' }}>{product.description}</Box>
        </Typography>
        <Typography
          variant="body2"
          sx={{ padding: '8px 0px', display: 'none' }}
          color="text.secondary"
        >
          Price: {product.BoxPrice}
        </Typography>
        <Rating value={product.rating} precision={0.5} readOnly />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#000000',
            color: 'white',
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Add to Bag
        </Button>
      </CardActions>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    BoxPrice: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProductCard;
