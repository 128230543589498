import React from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../Components/Footer/Footer";
import MenuCard from "../Components/MenuCards/MenuCards";

import Cardimg1 from "../Assets/Menu/MenuCArd10.png";
import Cardimg2 from "../Assets/Menu/MenuCArd11.png";
import Cardimg3 from "../Assets/Menu/MenuCArd13.png";
import Cardimg4 from "../Assets/Menu/MenuCArd14.png";
import Cardimg5 from "../Assets/Menu/MenuCArd7.png";
import Cardimg6 from "../Assets/Menu/MenuCArd12.png";
import Cardimg7 from "../Assets/Menu/vegan-lemongrass-steak.png";
import Cardimg8 from "../Assets/Menu/MenuCArd17.png";

import useMetaTracking from "../hooks/useMetaTracking";

function VeganBanhMi() {
  const navigate = useNavigate();

  useMetaTracking("ViewContent", {
    content_name: "Vegan Bánh Mì Page",
    content_category: "Vegan Menu",
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>Vegan Bánh Mì | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Enjoy our vegan bánh mì featuring fresh ingredients and authentic Vietnamese flavors. Discover our menu and savor a plant-based twist on a classic favorite."
        />
        <meta property="og:title" content="Vegan Bánh Mì | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Enjoy our vegan bánh mì featuring fresh ingredients and authentic Vietnamese flavors."
        />
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          py: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "35px",
                    fontFamily:
                      "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  VEGAN <br />
                  BÁNH MÌ
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.7em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  Enjoy our vegan bánh mì with fresh ingredients and authentic
                  Vietnamese flavors.
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.7em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  Filling includes choice of vegan base, vegan pâté spread,
                  pickled daikon and carrots, cucumber slices, fresh cilantro,
                  jalapeños, & soy sauce.
                </Typography>
                <Link href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x80dd26293bb9b18b:0xc29a7f9016ad7150?source=g.page.share">
                  <Button
                    sx={{
                      background: "#ffa500",
                      fontSize: "14px",
                      color: "#fff",
                      fontWeight: "700",
                      px: 3,
                    }}
                  >
                    Show bakery location
                  </Button>
                </Link>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {[
                    {
                      img: Cardimg1,
                      title: "Vegan Pork Meatballs – $8.00",
                      subTitle: "Xiú Mại Chay",
                      path: "/vegan-pork-meatballs",
                    },
                    {
                      img: Cardimg2,
                      title: "Vegan Pâté Meat Loaf – $8.00",
                      subTitle: "Pâté Chả Lụa Chay",
                      path: "/vegan-pate-meatloaf",
                    },
                    {
                      img: Cardimg3,
                      title: "Vegan Grilled Pork – $9.00",
                      subTitle: "THỊT Nướng Chay",
                      path: "/vegan-grilled-pork",
                    },
                    {
                      img: Cardimg4,
                      title: "Vegan Special Combination – $9.50",
                      subTitle: "Đặc Biệt Chay",
                      path: "/vegan-special-combo",
                    },
                    {
                      img: Cardimg5,
                      title: "Vegan Shredded Pork Skin – $8.00",
                      subTitle: "Bì Chay",
                      path: "/vegan-shredded-pork-skin",
                    },
                    {
                      img: Cardimg6,
                      title: "Vegan Grilled Chicken – $9.00",
                      subTitle: "Gà Nướng Chay",
                      path: "/vegan-grilled-chicken",
                    },
                    {
                      img: Cardimg7,
                      title: "Vegan Lemongrass Steak – $10.00",
                      subTitle: "BÍT TẾT SẢ CHAY",
                      path: "/vegan-lemongrass-steak",
                    },
                    {
                      img: Cardimg8,
                      title: "Vegan Fried Chicken – $10.50",
                      subTitle: "Gà Chiên Chay",
                      path: "/vegan-fried-chicken",
                    },
                  ].map((item, index) => (
                    <Grid
                      item
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                      key={index}
                    >
                      <MenuCard
                        img={item.img}
                        title={item.title}
                        subTitle={item.subTitle}
                        onAddToCart={() => {
                          navigate(item.path);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </HelmetProvider>
  );
}

export default VeganBanhMi;