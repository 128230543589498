import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import { Box, Container, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContactBg from "./../Assets/contactBG.jpg";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set page title for SEO
    document.title = "Thank You | Đông Hưng Viên Bakery";
    // Optional: Track page view if needed (e.g., Facebook Pixel)
    if (window.fbq) {
      window.fbq("track", "PageView", { page: "ThankYou" });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Thank You | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Thank you for your message! We will get back to you shortly."
        />
        <meta property="og:title" content="Thank You | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Your message has been received by Đông Hưng Viên Bakery."
        />
      </Helmet>
      <Box sx={{ background: "#0f0f0f", color: "#fff" }}>
        {/* Navbar at the top */}
        <Navbar />

        {/* Main Thank You content */}
        <Box
          sx={{
            minHeight: "calc(100vh - 128px)", // Adjust based on your Navbar and Footer heights
            pt: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url(${ContactBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        >
          <Container
            sx={{
              textAlign: "center",
              bgcolor: "rgba(0, 0, 0, 0.7)",
              color: "#fff",
              p: 5,
              borderRadius: 2
            }}
          >
            <CheckCircleIcon sx={{ fontSize: 60, color: "#4caf50" }} />
            <Typography variant="h3" gutterBottom>
              Thank You!
            </Typography>
            <Typography variant="h6" gutterBottom>
              Your message has been received. We will get back to you soon.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you have any urgent inquiries, please contact us at:
            </Typography>
            <Typography variant="h5" color="primary" gutterBottom>
              (714) 891-4404
            </Typography>
            <Typography variant="body2" gutterBottom>
              info@donghungvienbakery.com
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() => navigate("/")}
            >
              Back to Home
            </Button>
          </Container>
        </Box>

        {/* Footer at the bottom */}
        <Footer />
      </Box>
    </>
  );
};

export default ThankYouPage;