import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/CardBeef.png";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganBPC() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Beef Puff Pastry (Pâté Chaud)",
    content_category: "Vegan Menu",
    content_ids: ["vegan_beef_puff_pastry"],
    content_type: "vegan_vietnamese_pastries",
    value: 4.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Beef Puff Pastry (Pâté Chaud) | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="House-made vegan ‘beef’ filling wrapped in puff pastry, crafted from seitan & mushrooms, perfectly seasoned with five-spice, aromatic spices and sesame."
        />
        <meta
          property="og:title"
          content="Vegan Beef Puff Pastry (Pâté Chaud) | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="House-made vegan ‘beef’ filling wrapped in puff pastry, crafted from seitan & mushrooms, perfectly seasoned with five-spice, aromatic spices and sesame."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Beef Puff Pastry (Pâté Chaud)"
          titleSubHead="$4.00 each"
          detailBody="House-made vegan ‘beef’ filling wrapped in puff pastry, crafted from seitan & mushrooms, perfectly seasoned with five-spice, aromatic spices and sesame."
          detailBodyBr="Contains: wheat, soy, mushroom, sesame, almond"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganBPC;