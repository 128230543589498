import Footer from "../Components/Footer/Footer";
import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#141414 !important",
          py: 5,
          pt: 18,
        }}
      >
        <Box
          sx={{

            color: "#fff",
            maxWidth: "1080px",
          }}
        >
          <Grid
            container

            alignItems="center"
            justifyContent="center"
          >

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background: "#283341",
                  padding: "60px",
                  borderRadius: "0px",
                  boxShadow: {
                    xs: "none",
                    sm: "none",
                    md: "-80px -80px 1px 1px #4c4c4c",
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#FFDD59",
                    mb: 2,
                    transform: "skewY(-8deg)",
                    display: "inline-block",
                    fontFamily: "Poiret One",
                    fontSize: "40px",
                  }}
                >
                  OUR STORY
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Abhaya Libre Georgia Times New Roman serif",
                    lineHeight: 1.8,
                    mb: 4,
                    marginTop: "4rem",
                  }}
                >
                  Our journey began with a mission to celebrate Vietnam's
                  vibrant culinary heritage and make it accessible to all.
                  <br />
                  <br />
                  At Đông Hưng Viên Bakery, we're passionate about offering rich
                  and diverse Vietnamese flavors, complemented by delicious
                  vegan alternatives for everyone to enjoy.
                  <br />
                  <br />
                  Whether you're a long-time fan of Vietnamese food or trying it
                  for the first time, we promise a culinary experience that is
                  both unique and unforgettable.
                  <br />
                  <br />
                </Typography>
                <Link to="/contact">
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "20px",
                      borderRadius: "30px",
                      textTransform: "none",
                      backgroundColor: "#00AAE3",
                      "&:hover": {
                        backgroundColor: "#33BBE8",
                      },
                    }}
                  >
                    Contact Us
                  </Button>
                </Link>
              </Box>
            </Grid>

            {/* Right Section */}
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <Box
                sx={{
                  //   border: "2px solid #FFDD59",
                  //   borderRadius: "8px",
                  overflow: "hidden",
                  height: "100%",
                  marginTop: "2px",
                }}
              >
                {/* <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26461.78519820779!2d-118.34045025655036!3d34.09715717600469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c7d4c4021a8b%3A0xc1ffb71a1b3067e3!2sHollywood%20Walk%20of%20Fame!5e0!3m2!1sen!2sus!4v1699579813404!5m2!1sen!2sus"
                  width="100%"
                  height="706px"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default About;
