import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import {
  Checkbox,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function BookForm() {
  const [state, handleSubmit] = useForm("xjkgzkyz"); // Replace with your Formspree form ID
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    eventDate: "",
    eventTime: "",
    numGuests: "",
    eventDescription: "",
    referral: "",
    checkedItems: [],
  });

  useEffect(() => {
    if (state.succeeded) {
      if (window.fbq) {
        window.fbq("track", "Contact", {
          content_name: "Vietnamese Tea Ceremony Inquiry",
        });
      }
      navigate("/thank-you");
    }
  }, [state.succeeded, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => {
      let updatedCheckedItems = [...prev.checkedItems];
      if (checked) {
        updatedCheckedItems.push(value);
      } else {
        updatedCheckedItems = updatedCheckedItems.filter(
          (item) => item !== value
        );
      }
      return { ...prev, checkedItems: updatedCheckedItems };
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: "#283341",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          maxWidth: "800px",
          width: "90%",
          color: "white",
          mt: 5,
          position: "relative",
          zIndex: 2,
          // Universal font styling: Replace "Roboto, sans-serif" with your desired font
          "*": {
            fontFamily: "Roboto, sans-serif !important",
          },
        }}
      >
        <Box sx={{ maxWidth: "640px", width: "100%", mx: "auto" }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "400", textAlign: "center", mb: 2 }}
          >
            BOOK YOUR VIETNAMESE TEA CEREMONY WITH US!
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 3, lineHeight: 2, textAlign: "center", px: 2 }}
          >
            At Đông Hưng Viên Bakery, we specialize in bringing to life the
            sweetness of your engagement (Đám Hỏi) and wedding day with our
            bespoke cakes and personalized service.
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* First Name */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  First Name <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Last Name */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  Last Name <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  Email <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </FormControl>
            </Grid>

            {/* Phone */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  Phone <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Event Date */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  Date of Event <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="date"
                  name="eventDate"
                  value={formData.eventDate}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Event Time */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  Time of Event <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="time"
                  name="eventTime"
                  value={formData.eventTime}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Number of Guests */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  Number of Guests <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="number"
                  name="numGuests"
                  value={formData.numGuests}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Checkboxes */}
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: "14px" }}>
                SELECT WHICH ITEMS YOU’D LIKE TO ORDER FOR YOUR EVENT:{" "}
                <span style={{ color: "#f56c6c" }}>*</span>
              </Typography>
              <Grid container spacing={2}>
                {/* First column of checkboxes */}
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="banh-com"
                          checked={formData.checkedItems.includes("banh-com")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="BÁNH CỐM"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="xoi-gac"
                          checked={formData.checkedItems.includes("xoi-gac")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="XÔI GẤC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="trau-cau"
                          checked={formData.checkedItems.includes("trau-cau")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="TRẦU CAU (ARTIFICIAL)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="tea"
                          checked={formData.checkedItems.includes("tea")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="TEA (JASMINE OR OOLONG)"
                    />
                  </FormControl>
                </Grid>

                {/* Second column of checkboxes */}
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="banh-xu-xe"
                          checked={formData.checkedItems.includes("banh-xu-xe")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="BÁNH XU-XÊ"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="xoi-trai-tim"
                          checked={formData.checkedItems.includes("xoi-trai-tim")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="XÔI TRÁI TIM"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="khay-ruou"
                          checked={formData.checkedItems.includes("khay-ruou")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="KHAY RƯỢU"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="mam-qua"
                          checked={formData.checkedItems.includes("mam-qua")}
                          onChange={handleCheckboxChange}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#fff" },
                            "&.Mui-checked .MuiSvgIcon-root": { color: "#fff" },
                          }}
                        />
                      }
                      label="MÂM QUẢ"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Event Description */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  DESCRIBE YOUR EVENT (Are there any additional details you would like us to be aware of?)* 
                </label>
                <textarea
                  rows="5"
                  name="eventDescription"
                  value={formData.eventDescription}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Referral */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label style={{ fontSize: "14px" }}>
                  How Did You Hear About Us? <span style={{ color: "#f56c6c" }}>*</span>
                </label>
                <input
                  type="text"
                  name="referral"
                  value={formData.referral}
                  onChange={handleInputChange}
                  style={{
                    padding: "10px",
                    marginTop: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    outline: "none",
                  }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={state.submitting}
                sx={{
                  px: 2,
                  py: 1,
                  backgroundColor: "#00AAE3",
                  color: "white",
                  fontWeight: "bold",
                  mb: 3,
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "30px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Grid>
  );
}

export default BookForm;