import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./slider.css";
import React from "react";
import SliderImg1 from "../../Assets/FC.png";
import SliderImg2 from "../../Assets/Beef.png";
import SliderImg3 from "../../Assets/sliderImg3.png";
import SliderImg4 from "../../Assets/bookDream.png";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const slides = [
  {
    id: 1,
    image: SliderImg1,

    title: "Cozy Up To Our Crispiest Creation",
    description:
      "Perfectly crispy vegan fried ‘chicken’ that brings warmth in every bite. Available now!",
    buttonText: "Learn More",
    buttonLink: "/vegan-fried-chicken",
  },
  {
    id: 2,
    image: SliderImg2,
    title: "Vegan ‘Beef’ Pâté Chaud (Puff Pastry)",
    description: "A first of its kind. Available in-store now!",
    buttonText: "Learn More",
    buttonLink: "vegan-beef-puff-pastry",
  },
  {
    id: 3,
    image: SliderImg3,
    title: "It’s Better Vegan",
    description:
      "Experience our one-of-a-kind signature vegan coconut croissant. Visit us today and grab yours now!",
    buttonText: "Learn More",
    buttonLink: "vegan-coconut-croissant",
  },
  {
    id: 4,
    image: SliderImg4,
    title: "Book Your Dream Đám Hỏi",
    description:
      "Let your Vietnamese tea ceremony be as enchanting as your love story.",
    buttonText: "Learn More",
    buttonLink: "/vietnamese-tea-ceremony",
  },
];

const MuiReactSlider = () => {
  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        overflow: "hidden",
      }}
    >
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
        spaceBetween={50}
        slidesPerView={1}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: { xs: "50vh", sm: "70vh", md: "100vh" },
                backgroundImage: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
                color: "white",
                textShadow: "0px 2px 5px rgba(0, 0, 0, 0.8)",
                "&:before": {
                  zIndex: 1,
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  zIndex: 2,
                  background: "rgba(0, 0, 0, .5)",
                  padding: 5,
                  maxWidth: "590px",
                  width: "60%",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    maxWidth: "90%",
                    fontSize: { xs: "20px", sm: "40px", md: "40px" },
                    fontFamily:
                      "Abhaya Libre, Georgia, Times New Roman, serif;",
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    maxWidth: "90%",
                    fontSize: { xs: "14px", sm: "14px", md: "16px" },
                  }}
                >
                  {slide.description}
                </Typography>
                <Link
                  variant="contained"
                  color="primary"
                  to={slide.buttonLink}
                  style={{
                    fontFamily: "Open Sans, Arial, sans-serif",
                    color: "#fff",
                    borderRadius: "30px",
                    fontSize: "14px",
                    fontWeight: 700,
                    background: "#00AAE3",
                    padding: "10px 50px",
                    display: "inline-block",
                    marginTop: "14px",
                    lineHeight: "1.5em",
                    textShadow: "none",
                    textDecoration: "none",
                  }}
                >
                  {slide.buttonText}
                </Link>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default MuiReactSlider;
