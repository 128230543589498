import React from "react";
import Card from '../Components/HomeCards/Card';
import VeganCard from '../Components/HomeCards/VeganCard';
import Footer from '../Components/Footer/Footer';
import MuiReactSlider from '../Components/Slider/Slider';
import ReviewSection from '../Components/GoogleSlide/GoogleSlide';
import useMetaTracking from "../hooks/useMetaTracking";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/system';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Cardimg1 from './../Assets/BehnMi.png';
import Cardimg2 from './../Assets/PastriesBread.png';
import Cardimg3 from './../Assets/venPAstries.png';
import Cardimg4 from './../Assets/Drinks.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#00aae3',
  color: '#fff',
  fontWeight: 'bold',
  marginTop: '30px',
  padding: '10px 100px',
  textTransform: 'none',
  borderRadius: "30px",
  position: 'relative',
  overflow: 'hidden',
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: '#33BBE8',
  },

  '& .chevron-icon': {
    position: 'absolute',
    right: '100px',
    opacity: 0,
    transition: 'all 0.5s ease',
  },

  '&:hover .chevron-icon': {
    right: '70px',
    opacity: 1,
  },
}));

function Home() {
  useMetaTracking("ViewContent", {
    content_name: "Home Page",
    content_category: "Home Page",
    content_ids: ["home_page"],
    content_type: "home_page",
  });

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Home | Đông Hưng Viên Bakery</title>
          <meta
            name="description"
            content="Explore our authentic Vietnamese menu, including traditional and vegan bánh mì, vegan pastries, drinks, and more."
          />
          <meta
            name="keywords"
            content="Vietnamese food, vegan Vietnamese food, vegan bánh mì, Vietnamese bakery"
          />
          <meta name="author" content="donghungvienbakery" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="canonical" href="https://donghungvienbakery.vercel.app/" />
        </Helmet>
      </HelmetProvider>

      {/* Slider */}
      <MuiReactSlider />

      {/* Vegan Menu Links */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '50px 0px',
          background: '#141414 !important',
        }}
      >
        <Box sx={{ maxWidth: '1240px', width: '90%'}}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <Link to="/vegan-menu#vegan-banh-mi-section">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <VeganCard title="VEGAN BÁNH MÌ" img={Cardimg1} />
                </Box>
              </Link>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <Link to="/vegan-menu#VeganPastriesBread">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <VeganCard title="VEGAN PASTRIES" img={Cardimg2} />
                </Box>
              </Link>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <Link to="/vegan-menu#vegan-vietnamese-pastries">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <VeganCard title="VEGAN VIETNAMESE PASTRIES" img={Cardimg3} />
                </Box>
              </Link>
            </Grid>
            {/* Fixed here: changed "#vegan-drink" to "#vegan-drinks" */}
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <Link to="/vegan-menu#vegan-drinks">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <VeganCard title="VEGAN DRINKS" img={Cardimg4} />
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Contact / Visit Us Section */}
      <Box>
        <Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: '#283341 !important',
                  padding: '20px 10px',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: '30px',
                    margin: '15px 0px',
                    color: '#fff',
                    fontWeight: '500',
                  }}
                >
                  COME VISIT US
                </Typography>
                <Link to="/contact">
                  <StyledButton sx={{ fontSize: '16px' }}>
                    Contact Us
                    <ChevronRightIcon className="chevron-icon" />
                  </StyledButton>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Google Review Section */}
      <ReviewSection />

      {/* Footer */}
      <Footer />
    </>
  );
}

export default Home;