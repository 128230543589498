import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd3.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function PatePorkMeatloaf() {
  useMetaTracking("ViewContent", {
    content_name: "Pate Pork Meatloaf",
    content_category: "Regular Menu",
    content_ids: ["pate_pork_meatloaf"],
    content_type: "bánh mì",
    value: 7.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Pâté Pork Meatloaf | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Silky smooth pâté melts into delicate Vietnamese pork meatloaf (chả lụa), creating the ultimate contrast of creamy and tender inside a crispy, golden baguette."
        />
        <meta property="og:title" content="Pâté Pork Meatloaf | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Silky smooth pâté melts into delicate Vietnamese pork meatloaf (chả lụa), creating the ultimate contrast of creamy and tender inside a crispy, golden baguette."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Pâté Pork Meatloaf"
          titleSubHead="$7.50 each"
          detailBodyBr="Silky smooth pâté melts into delicate Vietnamese pork meatloaf (chả lụa), creating the ultimate contrast of creamy and tender inside a crispy, golden baguette."
          detailBody3="Contains: wheat, soy, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default PatePorkMeatloaf;