import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/croissant.png";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function Croissant() {
  useMetaTracking("ViewContent", {
    content_name: "Croissant",
    content_category: "Regular Menu",
    content_ids: ["croissant"],
    content_type: "pastries",
    value: 2.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Croissant | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="A buttery masterpiece with layers so delicate they practically melt in your mouth. Golden, crispy, and packed with rich, indulgent flavor—it’s the kind of croissant that keeps you coming back for more."
        />
        <meta property="og:title" content="Croissant | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="A buttery masterpiece with layers so delicate they practically melt in your mouth. Golden, crispy, and packed with rich, indulgent flavor—it’s the kind of croissant that keeps you coming back for more."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="Croissant"
          titleSubHead="$2.50 each"
          detailBody="A buttery masterpiece with layers so delicate they practically melt in your mouth. Golden, crispy, and packed with rich, indulgent flavor—it’s the kind of croissant that keeps you coming back for more."
          detailBodyBr="Contains: wheat, dairy, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default Croissant;