import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd8.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function FriedEgg() {
  useMetaTracking("ViewContent", {
    content_name: "Fried Egg",
    content_category: "Regular Menu",
    content_ids: ["fried_egg"],
    content_type: "bánh mì",
    value: 7.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Fried Egg | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Featuring perfectly fried sunny side-up eggs, nestled inside a crispy baguette. With rich, runny yolks soaking into the bread, every bite is comforting, satisfying, and full of flavor."
        />
        <meta property="og:title" content="Fried Egg | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Featuring perfectly fried sunny side-up eggs, nestled inside a crispy baguette. With rich, runny yolks soaking into the bread, every bite is comforting, satisfying, and full of flavor."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Fried Egg"
          titleSubHead="$7.50 each"
          detailBodyBr="Featuring perfectly fried sunny side-up eggs, nestled inside a crispy baguette. With rich, runny yolks soaking into the bread, every bite is comforting, satisfying, and full of flavor."
          detailBody3="Contains: wheat, soy, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default FriedEgg;