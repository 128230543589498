import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Divider,
} from '@mui/material';

import heroImage from '../Assets/img/hero.jpeg';
import product from '../Assets/DHV - transparent bg - lotus candy - lny 2025 (1).png';
import ProductCard from './ProductCard';
import { products } from '../utils/contants';
import Footer from '../Components/Footer/Footer';

const Products = () => {
  useEffect(() => {
    if (window.fbq) {
      products.forEach((item) => {
        window.fbq('track', 'ViewContent', {
          content_name: item.name,
          content_type: 'product',
          content_ids: [item.id],
          value: item.price || item.BagPrice || item.BoxPrice,
          currency: 'USD',
        });
      });
    }
  }, []);

  // Function to track AddToCart event
  const handleAddToCart = (item) => {
    if (window.fbq) {
      window.fbq('track', 'AddToCart', {
        content_name: item.name,
        content_type: 'product',
        content_ids: [item.id],
        value: item.price || item.BagPrice || item.BoxPrice,
        currency: 'USD',
      });
    }
    console.log(`${item.name} added to cart`);
  };

  return (
    <>
      <main>
        <section aria-labelledby="hero-section">
          <Box
            sx={{
              position: 'relative',
              height: { xs: '60vh', sm: '100vh' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: `url(${heroImage}) no-repeat center center/cover`,
              color: 'white',
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                inset: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            ></Box>

            <Container sx={{ zIndex: 1 }}>
              <Typography
                id="hero-section"
                component="h1"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1.5rem', sm: '4rem' },
                  mb: 2,
                  padding: '0px 20px',
                  textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
                }}
              >
                Sweeten Your Lunar New Year Celebrations
              </Typography>
              <Typography
                component="p"
                variant="h5"
                sx={{
                  mb: 4,
                  fontSize: { xs: '1rem', sm: '1.5rem' },
                  px: { xs: '1rem', sm: '1.5rem' },
                  textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
                }}
              >
                Our traditional candied treats feature authentic favorites
                perfect for gifting and enjoying during the holiday season.
                Available now through January 29th, 2025.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  fontWeight: 'bold',
                  px: 4,
                  py: 2,
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
              >
                Shop Now
              </Button>
            </Container>
          </Box>
        </section>
        <Box
          sx={{
            width: '90%',
            margin: '30px auto',
          }}
        >
          <Typography
            gutterBottom
            component="div"
            sx={{
              textAlign: 'center',
              color: '#D1BF8E',
              fontSize: '2.4rem',
              margin: '50px 0px',

              fontWeight: '500',
            }}
          >
            MENU
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-around',
              paddingTop: '3rem',
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '30rem' } }}>
              <Typography
                gutterBottom
                component="div"
                sx={{
                  textAlign: 'center',
                  color: '#D1BF8E',
                  fontSize: '1.8rem',

                  fontWeight: '500',
                }}
              >
                BAG
              </Typography>
              {products
                .filter((item) => item.BagPrice)
                .map((item) => {
                  return (
                    <Box mt={5}>
                      <Box
                        sx={{
                          display: 'flex',
                          color: 'white',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Box>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              fontSize: { xs: '.8rem', md: '.9rem' },

                              fontWeight: '500',
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              fontSize: { xs: '.7rem', md: '.9rem' },

                              fontWeight: '500',
                            }}
                          >
                            0.5lb bag
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            gap: '3rem',
                          }}
                        >
                          <Divider
                            sx={{
                              width: '8rem',
                              height: '1px',
                              bgcolor: 'white',
                              mt: 1.1,
                            }}
                          />
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              textAlign: 'center',

                              fontSize: '1rem',

                              fontWeight: '500',
                            }}
                          >
                            {item.BagPrice}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>

            <Box sx={{}}>
              <img src={product} alt="product" className="product" />
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '30rem' } }}>
              <Typography
                gutterBottom
                component="div"
                sx={{
                  textAlign: 'center',
                  color: '#D1BF8E',
                  fontSize: '1.8rem',

                  fontWeight: '500',
                }}
              >
                BOX
              </Typography>
              {products
                .filter((item) => item.BoxPrice)
                .map((item) => {
                  return (
                    <Box mt={5}>
                      <Box
                        sx={{
                          display: 'flex',
                          color: 'white',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Box>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              fontSize: { xs: '.7rem', md: '.9rem' },

                              fontWeight: '500',
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              fontSize: { xs: '.7rem', md: '.9rem' },

                              fontWeight: '500',
                            }}
                          >
                            1lb bag
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            gap: '3rem',
                          }}
                        >
                          <Divider
                            sx={{
                              width: '7rem',
                              height: '1px',
                              bgcolor: 'white',
                              mt: 1.5,
                            }}
                          />
                          <Typography
                            gutterBottom
                            component="div"
                            sx={{
                              textAlign: 'center',

                              fontSize: '1rem',

                              fontWeight: '500',
                            }}
                          >
                            {item.BoxPrice}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
        <section aria-labelledby="products-section">
          <Box
            sx={{
              py: 10,
              background: '#fff',
            }}
          >
            <Container>
              <Grid container spacing={4}>
                {products.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={product.id}
                    onClick={() => handleAddToCart(product)}
                  >
                    <ProductCard product={product} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Products;
