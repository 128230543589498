import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/vegan_coconut_croissant.png";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganCC() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Coconut Croissant",
    content_category: "Vegan Menu",
    content_ids: ["vegan_coconut_croissant"],
    content_type: "vegan_pastries",
    value: 2.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Coconut Croissant | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Indulge in the rich, flaky layers of our Vegan Coconut Croissant. Perfectly rolled, with delicate layers and a velvety coconut finish, these 100% vegan croissants deliver a satisfying crunch in every bite."
        />
        <meta property="og:title" content="Vegan Coconut Croissant | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Indulge in the rich, flaky layers of our Vegan Coconut Croissant. Perfectly rolled, with delicate layers and a velvety coconut finish, these 100% vegan croissants deliver a satisfying crunch in every bite."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>

      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Coconut Croissant"
          titleSubHead="$2.50 each"
          detailBody="Indulge in the rich, flaky layers of our Vegan Coconut Croissant. Perfectly rolled, with delicate layers and a velvety coconut finish, these 100% vegan croissants deliver a satisfying crunch in every bite."
          detailBodyBr="Contains: wheat, coconut"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganCC;