import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/Menu/MenuCArd18.png";
import useMetaTracking from "../hooks/useMetaTracking"; // Import the hook

function VeganC() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Croissant",
    content_category: "Vegan Menu",
    content_ids: ["vegan_croissant"],
    content_type: "vegan_pastries",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Croissant | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Each delicate layer is meticulously folded and baked to golden perfection, offering a crisp, flaky exterior that gives way to an airy, melt-in-your-mouth center. Light, yet deeply satisfying, it’s a simple pleasure that speaks for itself."
        />
        <meta
          property="og:title"
          content="Vegan Croissant | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Each delicate layer is meticulously folded and baked to golden perfection, offering a crisp, flaky exterior that gives way to an airy, melt-in-your-mouth center. Light, yet deeply satisfying, it’s a simple pleasure that speaks for itself."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>

      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Croissant"
          titleSubHead="$3.00 each"
          detailBody="Each delicate layer is meticulously folded and baked to golden perfection, offering a crisp, flaky exterior that gives way to an airy, melt-in-your-mouth center. Light, yet deeply satisfying, it’s a simple pleasure that speaks for itself."
          detailBodyBr="Contains: wheat, soy, almond"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganC;
