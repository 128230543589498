import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd1.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function ColdCutHam() {
  useMetaTracking("ViewContent", {
    content_name: "Cold Cut Ham",
    content_category: "Regular Menu",
    content_ids: ["cold_cut_ham"],
    content_type: "bánh mì",
    value: 7.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Cold Cut Ham | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="A simple yet flavorful combination of cold cut ham & pork meatloaf. Light, tender and perfectly layered in a crispy baguette - it's a classic bánh mì that never goes out of style."
        />
        <meta property="og:title" content="Cold Cut Ham | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="A simple yet flavorful combination of cold cut ham & pork meatloaf. Light, tender and perfectly layered in a crispy baguette - it's a classic bánh mì that never goes out of style."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Cold Cut Ham"
          titleSubHead="$7.50 each"
          detailBodyBr="A simple yet flavorful combination of cold cut ham & pork meatloaf. Light, tender and perfectly layered in a crispy baguette - it's a classic bánh mì that never goes out of style."
          detailBody3="Contains: wheat, soy, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default ColdCutHam;