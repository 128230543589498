import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/vegan_steamed_bun.png";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganSB() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Steamed Bun",
    content_category: "Vegan Menu",
    content_ids: ["vegan_steamed_bun"],
    content_type: "vegan_vietnamese_pastries",
    value: 3.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Steamed Bun | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Discover our light and fluffy Vegan Steamed Bun filled with soy, mushrooms & veggies. A delicious choice from Đông Hưng Viên Bakery."
        />
        <meta
          property="og:title"
          content="Vegan Steamed Bun | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="A light and fluffy Vietnamese steamed bun filled with soy, mushrooms & veggies. Perfect for a vegan delight."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="VEGAN Steamed Bun"
          titleSubHead="$3.00 each"
          detailBody="A light and fluffy Vietnamese steamed bun, filled with soy, mushrooms & veggies."
          detailBodyBr="Contains: wheat, soy, almond, mushrooms"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganSB;