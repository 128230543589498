import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Logo from "./../../Assets/logo.png";
import TikTokIcon from "./../../Assets/tik.png"
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const pages = [
  { name: 'Home', path: '/' },
  { name: 'Regular Menu', path: '/regular-menu' },
  { name: 'Vegan Menu', path: '/vegan-menu' },
  { name: 'Vegan Protein To-Go', path: '/vegan-protein-to-go' },
  { name: 'Vietnamese Tea Ceremony', path: '/vietnamese-tea-ceremony' },
  // { name: 'lunar new year', path: '/lunarnewyear' },
];

function Navbar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Detect small screens
  const location = useLocation(); // Access current route location

  // Update active page based on current route
  const activePage =
    pages.find((page) => page.path === location.pathname)?.name || 'Home';

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 500); // Adjust the threshold as needed
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: isSmallScreen
          ? 'rgba(41, 43, 53, .9)' // Fixed background for small screens
          : isScrolled
            ? 'rgba(41, 43, 53, .9)' // Background after scrolling for larger screens
            : 'transparent', // Transparent background initially for larger screens
        boxShadow:
          isScrolled || isSmallScreen
            ? '0px 2px 4px rgba(0, 0, 0, 0.1)'
            : 'none',
        transition: 'background-color 0.3s ease',
      }}
    >
      <Toolbar>
        {/* Logo */}
        <Typography
          variant="h6"
          component="a"
          href="#"
          sx={{
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'white',
            justifyContent: { xs: 'left', md: 'center' },
          }}
        >
          <Box
            sx={{
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              justifyContent: { xs: 'left', md: 'center' },
            }}
          >
            <Link to="/" style={{ textDecoration: 'none' }}>
              <img src={Logo} alt="Logo" style={{ width: '80px' }} />
            </Link>
          </Box>
        </Typography>

        {/* Menu for large screens */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
          }}
        >
          {pages.map((page) => (
            <Link
              key={page.name}
              to={page.path}
              style={{ textDecoration: 'none' }}
            >
              <Button
                sx={{
                  my: 2,
                  color: activePage === page.name ? '#00aae3' : 'white',
                  display: 'block',
                  '&:hover': { color: '#bebfc2' },
                  fontSize: '16px',
                  fontWeight: '700',
                  fontFamily: 'Lato, Helvetica, Arial, Lucida, sans-serif;',
                  textTransform: 'capitalize',
                }}
              >
                {page.name}
              </Button>
            </Link>
          ))}
        </Box>

        {/* Social Media Icons for large screens */}
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            gap: 2, // Spacing between icons
            flex: '1',
            justifyContent: 'left',
            marginTop: '10px',
          }}
        >
          {/* Facebook */}
          <a
            href="https://www.facebook.com/DongHungVienBakery/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              sx={{
                backgroundColor: 'white', // White circular background
                color: '#00aae3', // Blue icon color
                '&:hover': {
                  backgroundColor: '#f1f1f1', // Light grey on hover
                },
                width: 32, // Set circular size
                height: 32, // Set circular size
              }}
            >
              <FacebookOutlinedIcon />
            </IconButton>
          </a>

          {/* Instagram */}
          <a
            href="https://www.instagram.com/donghungvienbakery/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              sx={{
                backgroundColor: 'white',
                color: '#00aae3',
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                },
                width: 32,
                height: 32,
              }}
            >
              <InstagramIcon />
            </IconButton>
          </a>

          {/* TikTok */}
          <a
            href="https://www.tiktok.com/@donghungvienbakery"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              sx={{
                backgroundColor: 'white',
                color: '#00aae3',
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                },
                width: 32,
                height: 32,
              }}
            >
              <img
                width={'18px'}
		src={TikTokIcon}
                alt="tiktok"
              />
            </IconButton>
          </a>
        </Box>

        {/* Menu Toggle Button for small screens */}
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="regular-menu"
          sx={{ display: { xs: 'flex', md: 'none' } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        {/* Drawer for small screens */}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            '& .MuiDrawer-paper': {
              width: '250px',
              backgroundColor: '#121212',
              color: 'white',
            },
          }}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            {/* Menu Items */}
            <List>
              {pages.map((page) => (
                <ListItem
                  key={page.name}
                  disablePadding
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center !important',
                    textAlign: 'center',
                  }}
                >
                  <Link
                    to={page.path}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <ListItemButton
                      sx={{
                        '&:hover': { backgroundColor: '#2c2c2c' },
                      }}
                    >
                      <ListItemText
                        primary={page.name}
                        sx={{
                          textAlign: 'center',
                          color: activePage === page.name ? '#00aae3' : 'white',
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </List>

            <Divider sx={{ backgroundColor: '#444' }} />

            {/* Social Icons in Drawer */}
            <Box
              sx={{
                display: 'flex',
                gap: 2, // Spacing between icons
                flex: '1',
                justifyContent: 'center',
                marginTop: '10px',
                py: 2,
              }}
            >
              {/* Facebook */}
              <a
                href="https://www.facebook.com/DongHungVienBakery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  sx={{
                    backgroundColor: 'white', // White circular background
                    color: '#00aae3', // Blue icon color
                    '&:hover': {
                      backgroundColor: '#f1f1f1', // Light grey on hover
                    },
                    width: 32, // Set circular size
                    height: 32, // Set circular size
                  }}
                >
                  <FacebookOutlinedIcon />
                </IconButton>
              </a>

              {/* Instagram */}
              <a
                href="https://www.instagram.com/donghungvienbakery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  sx={{
                    backgroundColor: 'white',
                    color: '#00aae3',
                    '&:hover': {
                      backgroundColor: '#f1f1f1',
                    },
                    width: 32,
                    height: 32,
                  }}
                >
                  <InstagramIcon />
                </IconButton>
              </a>

              {/* TikTok */}
              <a
                href="https://www.tiktok.com/@donghungvienbakery"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  sx={{
                    backgroundColor: 'white',
                    color: '#00aae3',
                    '&:hover': {
                      backgroundColor: '#f1f1f1',
                    },
                    width: 32,
                    height: 32,
                  }}
                >
                  <img
                    width={'18px'}
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6S5muHqrLpZea8ipflxgXUxi33lChzyu_KW07KE_vqcPChe0qENUlFSA3xDyH9vUCYBs&usqp=CAU"
                    alt="tiktok"
                  />
                </IconButton>
              </a>
            </Box>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
