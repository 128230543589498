import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd11.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganPorkMeatLoaf() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Pork Meatloaf",
    content_category: "Vegan Menu",
    content_ids: ["vegan_pork_meatloaf"],
    content_type: "vegan_banh_mi",
    value: 8.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Pork Meatloaf | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Our house-made silky vegan Vietnamese pork meatloaf (chả lụa chay) is layered inside a crispy baguette, paired with our smooth, house-made vegan pâté for a rich, satisfying bite."
        />
        <meta
          property="og:title"
          content="Vegan Pork Meatloaf | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Our house-made silky vegan Vietnamese pork meatloaf (chả lụa chay) is layered inside a crispy baguette, paired with our smooth, house-made vegan pâté for a rich, satisfying bite."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Pork Meatloaf"
          titleSubHead="$8.00 each"
          detailBody="Our house-made silky vegan Vietnamese pork meatloaf (chả lụa chay) is layered inside a crispy baguette, paired with our smooth, house-made vegan pâté for a rich, satisfying bite."
          detailBodyBr="Contains: soy, mushroom"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganPorkMeatLoaf;