import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/vegan_vietnamese_donut.png";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganD() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Vietnamese Donut",
    content_category: "Vegan Menu",
    content_ids: ["vegan_vietnamese_donut"],
    content_type: "vegan_vietnamese_pastries",
    value: 1.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Vietnamese Donut | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="A traditional Vietnamese fried donut, known for its delicate, crisp exterior and soft, pillowy inside. With a hint of sweetness, it's the perfect snack for any time of day."
        />
        <meta
          property="og:title"
          content="Vegan Vietnamese Donut | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="A traditional Vietnamese fried donut, known for its delicate, crisp exterior and soft, pillowy inside. With a hint of sweetness, it's the perfect snack for any time of day."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="VEGAN VIETNAMESE DONUT"
          titleSubHead="$1.00 each"
          detailBody="A traditional Vietnamese fried donut, known for its delicate, crisp exterior and soft, pillowy inside. With a hint of sweetness, it's the perfect snack for any time of day."
          detailBodyBr="Contains: wheat, sesame"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganD;