import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd13.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganGrilledPork() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Grilled Pork",
    content_category: "Vegan Menu",
    content_ids: ["vegan_grilled_pork"],
    content_type: "vegan_banh_mi",
    value: 9.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Grilled Pork | Đông Hưng Viên Bakery</title>
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Grilled Pork"
          titleSubHead="$9.00 each"
          detailBody="Smoky, savory & packed with umami."
          detailBodyBr="Our vegan grilled pork is made from seitan, marinated in five-spice, chili powder, lemongrass & a blend of umami-rich sauces. Charred to perfection, each bite delivers deep caramelized flavors with just the right amount of heat."
          detailBody3="Contains: wheat, soy"
          detailBody4="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganGrilledPork;