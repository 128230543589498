import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BookForm from "../Components/BookForm/BookForm";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import Cardimg from "../Assets/vietnameseTea.png";
import vietnameseTeaMenu from "../Assets/vietnamese_tea_ceremony_events_menu.pdf";

function VietnameseTea() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        content_name: "Vietnamese Tea Ceremony Page",
        content_category: "Events",
      });
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Vietnamese Tea Ceremony | Đông Hưng Viên Bakery</title>
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        {/* Product Information & PDF Menu Button */}
        <ProductCard
          cardImg={Cardimg}
          titleHead="VIETNAMESE TEA CEREMONY (ĐÁM HỎI)"
          detailBody="A Vietnamese tea ceremony, commonly known as ‘Đám Hỏi’, is a profound blend of tradition and symbolism, celebrating not only the union of two souls but also the coming together of two families."
          detailBodyBr={
            <>
              <strong>Vietnamese Tea Ceremony Rental Policy:</strong> A $200 deposit is required to secure your Vietnamese tea ceremony rentals. This deposit will be returned in full once all rental items are returned within 48 hours of the event. If any item is not returned within that timeframe, the corresponding deposit will be forfeited. By renting our Vietnamese tea ceremony items, you agree to these terms.
            </>
          }          ProductCardBtn={
            <Button
              sx={{ color: "#fff", fontWeight: "700", px: 3 }}
              onClick={() => {
                window.open(
                  vietnameseTeaMenu,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              VIEW VIETNAMESE TEA CEREMONY MENU
            </Button>
          }
        />

        {/* Render the custom BookForm */}
        <BookForm />

        <Footer />
      </Box>
    </HelmetProvider>
  );
}

export default VietnameseTea;