import React from "react";
import { Helmet } from "react-helmet-async";
import CardImg from "./../Assets/Menu/MenuCArd10.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganPorkMeatballs() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Pork Meatballs",
    content_category: "Vegan Menu",
    content_ids: ["vegan_pork_meatballs"],
    content_type: "product",
    value: 8.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Pork Meatballs | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Tender vegan pork meatballs crafted from soy, tofu, mushrooms, jicama & tomatoes - each bite capturing smoky-sweet flavors with deep, umami-rich notes."
        />
        <meta
          property="og:title"
          content="Vegan Pork Meatballs | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Experience bold, smoky-sweet vegan pork meatballs crafted from soy, tofu, mushrooms, jicama & tomatoes, delivering deep, umami-rich flavors in every bite."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Pork Meatballs"
          titleSubHead="$8.00 each"
          detailBody="Tender vegan pork meatballs crafted from soy, tofu, mushrooms, jicama & tomatoes - each bite capturing smoky-sweet flavors with deep, umami-rich notes."
          detailBodyBr="Contains: soy, wheat, mushroom"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganPorkMeatballs;