import React, { useEffect } from "react";
import { Box, Grid, Typography, Card, CardActionArea, CardContent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Footer from "../Components/Footer/Footer";
import MenuCard from "../Components/MenuCards/MenuCards";
import { menuItems } from "../utils/contants";
import useMetaTracking from "../hooks/useMetaTracking";

// Navigation Icons
import Bao from "../Assets/nav_icons/bao.png";
import Cro from "../Assets/nav_icons/croissant.png";
import Dri from "../Assets/nav_icons/drink.png";
import San from "../Assets/nav_icons/sandwich.png";

// Menu Card Images (examples from your existing code)
import MenuCArd18 from "./../Assets/Menu/MenuCArd18.png";
import MenuCArd19 from "./../Assets/Menu/MenuCArd19.png";
import MenuCArd20 from "./../Assets/ham_and_cheese_croissant.png";
import MenuCArd26 from "./../Assets/Menu/MenuCArd26.png";
import MenuCArd27 from "./../Assets/Menu/MenuCArd27.png";
import MenuCArd34 from "./../Assets/1200x900_soft_mini_buns.png";
import MenuCArd39 from "../Assets/vietnamese_coffee.png";

function Menu() {
  const location = useLocation();
  const navigate = useNavigate();

  useMetaTracking("ViewContent", {
    content_name: "Regular Menu",
    content_category: "Regular Menu",
    content_ids: ["regular_menu"],
    content_type: "regular_menu",
  });

  // Scroll to section if there's a hash in the URL
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  // Fires "AddToCart" event for Meta Pixel and navigates to item link
  const handleCardClick = (item) => {
    navigate(item.link);
  };

  return (
    <>
      <Helmet>
        <title>Regular Menu | Đông Hưng Viên Bakery</title>
      </Helmet>

      {/* Page Header */}
      <Box sx={{ background: "#000 !important" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{
                mb: 3,
                lineHeight: 2,
                textAlign: "center",
                px: 2,
                pt: 15,
                color: "#fff",
                fontWeight: 700,
                fontSize: "35px",
                fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                textTransform: "uppercase",
              }}
            >
              Regular Menu
            </Typography>
          </Grid>
        </Grid>
      </Box>

{/* Section Tabs (Apple-style menu) */}
<Box sx={{ display: "flex", justifyContent: "center", background: "#000" }}>
  <Box sx={{ maxWidth: "1080px", width: "90%" }} id="Bahn">
    <Grid container spacing={0}>

      {/* BÁNH MÌ */}
      <Grid item lg={3} md={6} sm={6} xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              maxWidth: "333px !important",
              width: "100%",
              background: "transparent",
              color: "#fff",
              boxShadow: "none",
              borderRadius: 0,
              margin: "5px 2px !important",
            }}
          >
            <CardActionArea
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                const section = document.getElementById("banh-mi-section");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                "&:hover": {
                  background: "transparent !important",
                  "& .navText": {
                    fontWeight: "1000", // Text becomes bolder on hover
                  },
                },
                // Remove the default focus highlight overlay
                "& .MuiCardActionArea-focusHighlight": {
                  backgroundColor: "transparent !important",
                  opacity: 0,
                },
              }}
            >
              <img
                src={San}
                alt="Bánh Mì Sandwiches"
                style={{ width: "40px", marginBottom: "8px" }}
              />
              <Typography
                className="navText"
                gutterBottom
                variant="body1"
                component="div"
                sx={{
                  fontSize: "14px",
                  fontWeight: "300",
                  textTransform: "uppercase",
                }}
              >
                BÁNH MÌ
              </Typography>
            </CardActionArea>
          </Card>
        </Box>
      </Grid>

      {/* PASTRIES */}
      <Grid item lg={3} md={6} sm={6} xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              maxWidth: "333px !important",
              width: "100%",
              background: "transparent",
              color: "#fff",
              boxShadow: "none",
              borderRadius: 0,
              margin: "5px 2px !important",
            }}
          >
            <CardActionArea
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                const section = document.getElementById("pastriesBread");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                "&:hover": {
                  background: "transparent !important",
                  "& .navText": {
                    fontWeight: "1000",
                  },
                },
                "& .MuiCardActionArea-focusHighlight": {
                  backgroundColor: "transparent !important",
                  opacity: 0,
                },
              }}
            >
              <img
                src={Cro}
                alt="Pastries"
                style={{ width: "40px", marginBottom: "8px" }}
              />
              <Typography
                className="navText"
                gutterBottom
                variant="body1"
                component="div"
                sx={{
                  fontSize: "14px",
                  fontWeight: "300",
                  textTransform: "uppercase",
                }}
              >
                PASTRIES
              </Typography>
            </CardActionArea>
          </Card>
        </Box>
      </Grid>

      {/* VIETNAMESE PASTRIES */}
      <Grid item lg={3} md={6} sm={6} xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              maxWidth: "333px !important",
              width: "100%",
              background: "transparent",
              color: "#fff",
              boxShadow: "none",
              borderRadius: 0,
              margin: "5px 2px !important",
            }}
          >
            <CardActionArea
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                const section = document.getElementById("vientnamesePastries");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                "&:hover": {
                  background: "transparent !important",
                  "& .navText": {
                    fontWeight: "1000",
                  },
                },
                "& .MuiCardActionArea-focusHighlight": {
                  backgroundColor: "transparent !important",
                  opacity: 0,
                },
              }}
            >
              <img
                src={Bao}
                alt="Vietnamese Pastries"
                style={{ width: "40px", marginBottom: "8px" }}
              />
              <Typography
                className="navText"
                gutterBottom
                variant="body1"
                component="div"
                sx={{
                  fontSize: "14px",
                  fontWeight: "300",
                  textTransform: "uppercase",
                }}
              >
                VIETNAMESE PASTRIES
              </Typography>
            </CardActionArea>
          </Card>
        </Box>
      </Grid>

      {/* DRINKS */}
      <Grid item lg={3} md={6} sm={6} xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              maxWidth: "333px !important",
              width: "100%",
              background: "transparent",
              color: "#fff",
              boxShadow: "none",
              borderRadius: 0,
              margin: "5px 2px !important",
            }}
          >
            <CardActionArea
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                const section = document.getElementById("drinks");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                "&:hover": {
                  background: "transparent !important",
                  "& .navText": {
                    fontWeight: "1000",
                  },
                },
                "& .MuiCardActionArea-focusHighlight": {
                  backgroundColor: "transparent !important",
                  opacity: 0,
                },
              }}
            >
              <img
                src={Dri}
                alt="Drinks"
                style={{ width: "40px", marginBottom: "8px" }}
              />
              <Typography
                className="navText"
                gutterBottom
                variant="body1"
                component="div"
                sx={{
                  fontSize: "14px",
                  fontWeight: "300",
                  textTransform: "uppercase",
                }}
              >
                DRINKS
              </Typography>
            </CardActionArea>
          </Card>
        </Box>
      </Grid>

    </Grid>
  </Box>
</Box>

      {/* BÁNH MÌ SANDWICHES SECTION */}
      <Box
        id="banh-mi-section"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "left",
                    },
                  }}
                >
                  BÁNH MÌ 
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {menuItems.map((item, index) => (
                    <Grid
                      item
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                      key={index}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <MenuCard
                        img={item.img}
                        title={item.title}
                        subTitle={item.subTitle}
                        onAddToCart={() => handleCardClick(item)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Divider */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ borderTop: "1px solid #fff" }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* PASTRIES SECTION */}
      <Box
        id="pastriesBread"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 15,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "left",
                    },
                  }}
                >
                  PASTRIES
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {/* Example Pastry Items */}
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={MenuCArd18}
                      title="Croissant"
                      onAddToCart={() => {
                        navigate("/croissant");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={MenuCArd19}
                      title="Coconut Croissant"
                      onAddToCart={() => {
                        navigate("/coconut-croissant");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={MenuCArd20}
                      title="Ham & Cheese Croissant"
                      onAddToCart={() => {
                        navigate("/ham-and-cheese-croissant");
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Divider */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ borderTop: "1px solid #fff" }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* VIETNAMESE PASTRIES SECTION */}
      <Box
        id="vientnamesePastries"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 15,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "left",
                    },
                  }}
                >
                  VIETNAMESE <br /> PASTRIES
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {/* Example Vietnamese Pastry Items */}
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={MenuCArd26}
                      title="Vietnamese Steamed Bun"
                      subTitle="Pork"
                      onAddToCart={() => {
                        navigate("/steamed-bun");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={MenuCArd27}
                      title="Vietnamese Puff Pastry"
                      subTitle="Pork, Chicken"
                      onAddToCart={() => {
                        navigate("/puff-pastry");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={MenuCArd34}
                      title="Soft Mini Buns"
                      subTitle="Includes Egg. Coconut, Taro, Mung Bean, Durian"
                      onAddToCart={() => {
                        navigate("/soft-mini-buns");
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Divider */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ borderTop: "1px solid #fff" }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* DRINKS SECTION */}
      <Box
        id="drinks"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          pt: 15,
          pb: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={0}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    pt: 1,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "30px",
                    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "left",
                    },
                  }}
                >
                  DRINKS
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {/* Example Drinks */}
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <MenuCard
                      img={MenuCArd39}
                      title="Vietnamese COFFEE"
                      subTitle="CÀ PHÊ SỮA ĐÁ"
                      onAddToCart={() => {
                        navigate("/vietnamese-coffee");
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </>
  );
}

export default Menu;
