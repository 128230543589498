import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd14.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganSpecialCombo() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Special Combo",
    content_category: "Vegan Menu",
    content_ids: ["vegan_special_combo"],
    content_type: "vegan_banh_mi",
    value: 9.5,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Special Combo | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Our vegan special combination sandwich brings together vegan meats like ham, Vietnamese meatloaf & smoky-sweet roasted pork in a crispy baguette. Topped with pickled veggies and fresh herbs, this bánh mì delivers the perfect balance of richness, crunch, and bold, nostalgic flavors."
        />
        <meta
          property="og:title"
          content="Vegan Special Combo | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Our vegan special combination sandwich brings together vegan meats like ham, Vietnamese meatloaf & smoky-sweet roasted pork in a crispy baguette. Topped with pickled veggies and fresh herbs, this bánh mì delivers the perfect balance of richness, crunch, and bold, nostalgic flavors."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Special Combo"
          titleSubHead="$9.50 each"
          detailBody="Took a classic and turned it vegan."
          detailBodyBr="Our vegan special combination sandwich brings together vegan meats like ham, Vietnamese meatloaf & smoky-sweet roasted pork in a crispy baguette. Topped with pickled veggies and fresh herbs, this bánh mì delivers the perfect balance of richness, crunch, and bold, nostalgic flavors."
          detailBody3="Contains: wheat, soy"
          detailBody4="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganSpecialCombo;