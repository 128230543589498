import ContactBg from "./../Assets/contactBG.jpg";
import ContactForm from "./ContactForm";
import Footer from "../Components/Footer/Footer";
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const ContactPage = () => {
  const navigate = useNavigate()
  // Fire Meta Pixel "ViewContent" event when the page loads
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', {
        content_name: 'Contact Page',
      });
    }
  }, []);

  // State to track form data
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  // Handle changes to input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Fire Meta Pixel "Contact" event on form submit
    if (window.fbq) {
      window.fbq('track', 'Contact', {
        content_name: 'Contact Form Submission - Contact Page',
      });
    }

    // Create mailto link with form details
    // const subject = encodeURIComponent(
    //   `Contact Form Submission from ${formData.firstName} ${formData.lastName}`
    // );
    // const body = encodeURIComponent(`
    //   First Name: ${formData.firstName}
    //   Last Name: ${formData.lastName}
    //   Email: ${formData.email}
    //   Phone: ${formData.phone}
    //   Message:
    //   ${formData.message}
    //   `);

    // window.location.href = `mailto:info@donghungvienbakery.com?subject=${subject}&body=${body}`;

    navigate('/thank-you')
  };

  return (
    <>
      <Helmet>
        <title>Contact | Đông Hưng Viên Bakery</title>
      </Helmet>

      <Box
        sx={{
          backgroundImage: `url(${ContactBg})`,
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Box
          sx={{
            background: 'rgba(0,0,0,0.7)',
            p: 4,
            display: 'flex',
            justifyContent: 'center',
            pt: 15,
          }}
        >
          <Box sx={{ maxWidth: '1080px' }}>
            <Typography
              variant="h3"
              align="left"
              sx={{
                fontSize: { xs: '40px', sm: '60px', md: '80px' },
                fontWeight: '700',
                fontFamily: 'Poiret One',
                letterSpacing: '12px',
                pb: 5,
              }}
              gutterBottom
            >
              CONTACT
            </Typography>

            <Grid container spacing={8}>
              {/* LEFT: Contact Form */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    py: 4,
                  }}
                >
                  {/* <form onSubmit={handleFormSubmit}>

                    <Box
                      sx={{
                        display: { xs: 'block', sm: 'block', md: 'flex' },
                        justifyContent: 'space-between',
                        gap: '30px',
                      }}
                    >
                      <FormControl fullWidth>
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          style={{
                            padding: '15px 10px',
                            border: '1px solid #ccc',
                            borderRadius: '2px',
                            outline: 'none',
                          }}
                        />
                      </FormControl>
                      <FormControl
                        sx={{
                          marginTop: {
                            xs: '20px !important',
                            sm: '20px !important',
                            md: '0px !important',
                          },
                        }}
                        fullWidth
                      >
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          style={{
                            padding: '15px 10px',
                            border: '1px solid #ccc',
                            borderRadius: '2px',
                            outline: 'none',
                          }}
                        />
                      </FormControl>
                    </Box>
                    <FormControl fullWidth>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleInputChange}
                        style={{
                          padding: '15px 10px',
                          marginTop: '20px',
                          border: '1px solid #ccc',
                          borderRadius: '2px',
                          outline: 'none',
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleInputChange}
                        style={{
                          padding: '15px 10px',
                          marginTop: '20px',
                          border: '1px solid #ccc',
                          borderRadius: '2px',
                          outline: 'none',
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <textarea
                        rows="5"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleInputChange}
                        style={{
                          padding: '15px 10px',
                          marginTop: '20px',
                          border: '1px solid #ccc',
                          borderRadius: '2px',
                          outline: 'none',
                        }}
                      />
                    </FormControl>
                    <Button
                      sx={{ marginTop: '20px' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form> */}
                  <ContactForm></ContactForm>
                </Box>
              </Grid>

              {/* RIGHT: Contact Info */}
              <Grid item xs={12} md={6}>
                <Box sx={{ borderRadius: 2 }}>
                  <Typography
                    sx={{
                      fontSize: '40px',
                      color: '#2ea3f2',
                      fontWeight: '700',
                      lineHeight: '45px',
                    }}
                    variant="h2"
                  >
                    (714) 891-4404
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '14px',
                      color: '#fff',
                      lineHeight: '1.4em',
                      fontWeight: '700',
                      textTransform: 'uppercase',
                      pt: 3,
                      pb: 2,
                    }}
                  >
                    Address:
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '16px',
                      color: '#fff',
                      lineHeight: '1.4em',
                      fontWeight: '400',
                      textTransform: 'capitalize',
                      py: 0,
                    }}
                  >
                    8536 Westminster Ave, Westminster, CA 92683
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '14px',
                      color: '#fff',
                      lineHeight: '1.4em',
                      fontWeight: '700',
                      textTransform: 'uppercase',
                      pt: 3,
                      pb: 2,
                    }}
                  >
                    Opening Hours:
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '16px',
                      color: '#fff',
                      lineHeight: '1.4em',
                      fontWeight: '400',
                      textTransform: 'capitalize',
                      py: 0,
                    }}
                  >
                    Daily 6am – 8pm
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '14px',
                      color: '#fff',
                      lineHeight: '1.4em',
                      fontWeight: '700',
                      textTransform: 'uppercase',
                      pt: 3,
                      pb: 2,
                    }}
                  >
                    Email:
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '16px',
                      color: '#fff',
                      lineHeight: '1.4em',
                      fontWeight: '400',
                      py: 0,
                    }}
                  >
                    <Link
                      href="mailto:info@donghungvienbakery.com"
                      color="inherit"
                    >
                      info@donghungvienbakery.com
                    </Link>
                  </Typography>
                  {/* Social Icons Removed */}
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              {/* 
                Uncomment if you want to display Google Map:
                
                <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.9298714957526!2d-117.96420482449493!3d33.759582027318516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd279b9cda3cf1%3A0xa1b75bcfd913470!2sDong%20Hung%20Vien%20Bakery!5e0!3m2!1sen!2sus!4v1681067261990!5m2!1sen!2sus"
                  width="100%"
                  height="400"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ContactPage;