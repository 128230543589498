import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd7.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganShreddedPorkSkin() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Shredded Pork Skin",
    content_category: "Vegan Menu",
    content_ids: ["vegan_shredded_pork_skin"],
    content_type: "vegan_banh_mi",
    value: 8.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Shredded Pork Skin | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Our vegan shredded pork skin sandwich (bánh mì bì) brings together delicate strands of shredded tofu skin and root vegetables (jicama, carrot), creating a satisfyingly chewy, crunchy, and dangerously delicious filling."
        />
        <meta
          property="og:title"
          content="Vegan Shredded Pork Skin | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Our vegan shredded pork skin sandwich (bánh mì bì) brings together delicate strands of shredded tofu skin and root vegetables (jicama, carrot), creating a satisfyingly chewy, crunchy, and dangerously delicious filling."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Shredded Pork Skin"
          titleSubHead="$8.00 each"
          detailBody="Our vegan shredded pork skin sandwich (bánh mì bì) brings together delicate strands of shredded tofu skin and root vegetables (jicama, carrot), creating a satisfyingly chewy, crunchy, and dangerously delicious filling."
          detailBodyBr="Contains: soy, wheat"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganShreddedPorkSkin;