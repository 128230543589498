import React, { useEffect } from "react";
import ProductCard from "../Components/VeganProtienCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";

import Cardimg from "./../Assets/veganProtien.png";
import { Helmet } from 'react-helmet-async';

function VeganProtien() {
  // Meta Pixel ViewContent event on component mount
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', {
        content_name: 'Vegan Protein To-Go | Đông Hưng Viên Bakery',
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Vegan Protein To-Go | Đông Hưng Viên Bakery</title>
      </Helmet>

      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <ProductCard
          cardImg={Cardimg}
          titleHead="VEGAN PROTEIN TO-GO"
          detailHead="Customize your meals at home with our vegan ingredients."
          detailBody="Your favorite vegan proteins are now available in convenient To-Go boxes for purchase, allowing you to create any meal you desire in the comfort of your home. Fully prepared & ready to be added to your favorite recipes. Options include:"
          detailSubHead1="Vegan Grilled Pork or Chicken:"
          listData1="8oz – $12"
          listData2="1lb – $24"
          detailSubHead2="Vegan Fried Chicken:"
          listData3="8oz – $12"
          listData4="1lb – $24"
          detailSubHead3="Vegan Lemongrass Beef:"
          listData5="8oz – $15"
          listData6="1lb – $30"
          detailSubHead4="Vegan Meatloaf (Roll):"
          listData7="1lb – $12"
          listData8="2lb – $23"
          detailSubHead5="Vegan Pâté:"
          listData9="8oz – $12.50"
          listData10="1lb – $25"
          detailBody2="Perfect for sandwiches, salads, bowls or other creative dishes."
          ProductCardBtn="View All Vegan Bánh Mì"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganProtien;