import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/che_duong_nhan.png";
import useMetaTracking from "../hooks/useMetaTracking";

function BE() {
  useMetaTracking("ViewContent", {
    content_name: "Vietnamese Beauty Elixir",
    content_category: "Vegan Menu",
    content_ids: ["vietnamese_beauty_elixir"],
    content_type: "vegan_vietnamese_drinks",
    value: 7.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vietnamese Beauty Elixir | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="A traditional Vietnamese sweet dessert soup (Che Dưỡng Nhan) crafted to nourish & rejuvenate your skin. Our unique blend features premium natural ingredients like snow nest, dried red apple, longan, chia seeds, goji berries, & more – all known for their beauty-enhancing & wellness benefits."
        />
        <meta
          property="og:title"
          content="Vietnamese Beauty Elixir | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="A traditional Vietnamese sweet dessert soup (Che Dưỡng Nhan) crafted to nourish & rejuvenate your skin. Our unique blend features premium natural ingredients like snow nest, dried red apple, longan, chia seeds, goji berries, & more – all known for their beauty-enhancing & wellness benefits."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="VIETNAMESE Beauty Elixir"
          titleSubHead="$7.00 each"
          detailBody="A traditional Vietnamese sweet dessert soup (Che Dưỡng Nhan) crafted to nourish & rejuvenate your skin. Our unique blend features premium natural ingredients like snow nest, dried red apple, longan, chia seeds, goji berries, & more – all known for their beauty-enhancing & wellness benefits."
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default BE;