import React from "react";
import CardImg from "./../Assets/Menu/vegan-lemongrass-steak.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganLemongrassSteak() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Lemongrass Steak",
    content_category: "Vegan Menu",
    content_ids: ["vegan_lemongrass_steak"],
    content_type: "vegan_banh_mi",
    value: 10.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Lemongrass Steak | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Infused with bold flavors, our vegan lemongrass steak combines soy protein and shiitake mushrooms, marinated in five-spice, cumin, paprika, soy sauce, and mushroom powder for a rich, smoky depth. Grilled to perfection, it delivers a tender, satisfying bite with a slightly charred crust."
        />
        <meta
          property="og:title"
          content="Vegan Lemongrass Steak | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Infused with bold flavors, our vegan lemongrass steak combines soy protein and shiitake mushrooms, marinated in five-spice, cumin, paprika, soy sauce, and mushroom powder for a rich, smoky depth. Grilled to perfection, it delivers a tender, satisfying bite with a slightly charred crust."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Lemongrass Steak"
          titleSubHead="$10.00 each"
          detailBody="Infused with bold flavors, our vegan lemongrass steak combines soy protein and shiitake mushrooms, marinated in five-spice, cumin, paprika, soy sauce, and mushroom powder for a rich, smoky depth. Grilled to perfection, it delivers a tender, satisfying bite with a slightly charred crust."
          detailBodyBr="Contains: soy, mushroom, sesame"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganLemongrassSteak;