import { useEffect } from "react";

/**
 * Returns the value of a cookie by name, or null if not found.
 */
function getCookieValue(key) {
  const match = document.cookie.match(new RegExp(`${key}=([^;]+)`));
  return match ? match[1] : null;
}

/**
 * Get the fbclid from the URL, if present (e.g. ?fbclid=AbCdEf).
 */
function getFbclid() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("fbclid");
}

/**
 * Format a new _fbc cookie value:
 * Example: "fb.1.1677325050487.AbCdEf..."
 */
function formatFbc(fbclid) {
  if (!fbclid) return "";
  const subdomainIndex = 1;       // or 2 for "www.example.com"
  const creationTime = Date.now(); 
  return `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
}

/**
 * Format a new _fbp cookie value:
 * Example: "fb.1.1596403881668.1116446470"
 */
function formatFbp() {
  const subdomainIndex = 1;
  const creationTime = Date.now();
  // any random number for uniqueness
  const randomNumber = Math.floor(Math.random() * 10_000_000_000);
  return `fb.${subdomainIndex}.${creationTime}.${randomNumber}`;
}

/**
 * Generic helper to store a cookie with a 90-day expiration.
 * 
 * @param {string} key   The cookie name, e.g. "_fbc" or "_fbp"
 * @param {string} value The cookie value
 */
function setCookie(key, value) {
  if (!value) return;
  const expireMs = 90 * 24 * 60 * 60 * 1000; // 90 days in ms
  const expireDate = new Date(Date.now() + expireMs).toUTCString();
  // By default, set Path=/ and SameSite=Lax. Modify domain if needed.
  document.cookie = `${key}=${value}; Expires=${expireDate}; Path=/; SameSite=Lax;`;
}

const useMetaTracking = (eventName, eventData) => {
  useEffect(() => {
    if (typeof window === "undefined") return;

    // 1️⃣ Generate a unique event ID for deduplication
    const eventID = `event_${Date.now()}`;

    // 2️⃣ Read existing cookies
    let fbpCookie = getCookieValue("_fbp"); // e.g. "fb.1.1671234567.987654321"
    let fbcCookie = getCookieValue("_fbc"); // e.g. "fb.1.1671234567.AbCdEf..."

    // 3️⃣ Check if user arrived via fbclid
    const fbclidParam = getFbclid();

    // 4️⃣ If no _fbc but we have fbclid, create & store a new _fbc
    if (!fbcCookie && fbclidParam) {
      const newFbc = formatFbc(fbclidParam);
      setCookie("_fbc", newFbc);
      fbcCookie = newFbc;
    }

    // 5️⃣ If no _fbp cookie, generate & store one
    //    This ensures coverage even if the pixel didn't set it
    if (!fbpCookie) {
      const newFbp = formatFbp();
      setCookie("_fbp", newFbp);
      fbpCookie = newFbp;
    }

    // Optional fields if you have them
    const external_id = "USER_12345"; // Demo
    const fb_login_id = null;         // If user logs in via FB

    // 6️⃣ Gather userAgent & location
    const userAgent = navigator.userAgent;
    const eventSourceUrl = window.location.href;

    // 7️⃣ Build Conversions API payload
    const requestBody = {
      event_name: eventName,
      ...eventData,

      // Dedup & advanced matching
      event_id: eventID,
      fbp: fbpCookie || undefined,
      fbc: fbcCookie || undefined,
      fbclid: fbclidParam || undefined,
      external_id,
      fb_login_id,

      // “website” event
      action_source: "website"
    };

    // 8️⃣ Send event to Express server
    fetch("https://express-railway-api-production.up.railway.app/meta", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent": userAgent,
        "Referer": eventSourceUrl,
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => console.log("Meta event sent (server):", data))
      .catch((err) => console.error("Error sending Meta event:", err));

    // 9️⃣ Also send to Meta Pixel
    if (window.fbq) {
      window.fbq("track", eventName, eventData, { eventID });
    }
  }, [eventName, eventData]);
};

export default useMetaTracking;
