import CardImg from "../Assets/CardFC.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import React from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganFC() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Fried Chicken",
    content_category: "Vegan Menu",
    content_ids: ["vegan_fried_chicken"],
    content_type: "vegan_banh_mi",
  });

  return (
    <>
      {/* Page Title */}
      <Helmet>
        <title>Vegan Fried Chicken | Đông Hưng Viên Bakery</title>
      </Helmet>

      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="VEGAN FRIED CHICKEN"
          titleSubHead="$10.50 per sandwich | À La Carte: $12 per half-pound"
          detailBody="Fresh oyster mushrooms are transformed into vegan fried ‘chicken’ seasoned with aromatic spices, achieving the perfect golden crunch. The dish is served in a classic bánh mì with house-made spicy vegan mayo for the ultimate comfort food experience."
          detailBodyBr="Contains: wheat, mushroom, almond"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganFC;