import React, { useState, useEffect } from "react";
import { ValidationError, useForm } from "@formspree/react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ContactForm = () => {
  const navigate = useNavigate();
  // Replace "myzkdvwe" with your actual Formspree form ID if needed.
  const [state, handleSubmit] = useForm("myzkdvwe");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: ""
  });

  // Fire the "ViewContent" event when the component mounts.
  useEffect(() => {
    console.log("ContactForm mounted: firing ViewContent event.");
    if (window.fbq) {
      window.fbq("track", "ViewContent");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // If submission succeeds, fire "Contact" event and navigate to Thank You page.
  if (state.succeeded) {
    console.log("Form submission succeeded!");
    if (window.fbq) {
      window.fbq("track", "Contact");
    }
    navigate("/thank-you");
  }

  // Optional: log Formspree errors for debugging.
  useEffect(() => {
    if (state.errors && state.errors.length > 0) {
      console.error("Formspree errors:", state.errors);
    }
  }, [state.errors]);

  return (
    <>
      <Helmet>
        <title>Contact Us | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Get in touch with Đông Hưng Viên Bakery for inquiries, orders, and more."
        />
        <meta property="og:title" content="Contact Us | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Contact Đông Hưng Viên Bakery for all your inquiries & more."
        />
      </Helmet>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "flex" },
              justifyContent: "space-between",
              gap: "30px"
            }}
          >
            <FormControl fullWidth>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                style={{
                  padding: "15px 10px",
                  border: "1px solid #ccc",
                  borderRadius: "2px",
                  outline: "none"
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              sx={{
                marginTop: {
                  xs: "20px !important",
                  sm: "20px !important",
                  md: "0px !important"
                }
              }}
            >
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                style={{
                  padding: "15px 10px",
                  border: "1px solid #ccc",
                  borderRadius: "2px",
                  outline: "none"
                }}
              />
            </FormControl>
          </Box>

          <FormControl fullWidth>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleInputChange}
              style={{
                padding: "15px 10px",
                marginTop: "20px",
                border: "1px solid #ccc",
                borderRadius: "2px",
                outline: "none"
              }}
              required
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </FormControl>

          <FormControl fullWidth>
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
              style={{
                padding: "15px 10px",
                marginTop: "20px",
                border: "1px solid #ccc",
                borderRadius: "2px",
                outline: "none"
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <textarea
              rows="5"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleInputChange}
              style={{
                padding: "15px 10px",
                marginTop: "20px",
                border: "1px solid #ccc",
                borderRadius: "2px",
                outline: "none"
              }}
              required
            />
            <ValidationError prefix="Message" field="message" errors={state.errors} />
          </FormControl>

          <Button
  sx={{
    marginTop: "20px",
    backgroundColor: "#00AAE3",
    textTransform: "none",
  borderRadius: "30px",
    "&:hover": {
      backgroundColor: "#33BBE8",
    },
  }}
  variant="contained"
  type="submit"
  disabled={state.submitting}
>
  Submit
</Button>
        </form>
      </Box>
    </>
  );
};

export default ContactForm;