import React, { useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#00AAE3", // Button background color
  color: "#fff",
  fontWeight: "bold",
  marginTop: "30px",
  padding: "10px 30px",
  textTransform: "none",
  borderRadius: "30px",
  position: "relative",
  overflow: "hidden",
  transition: "background-color 0.3s ease",

  // Change hover background
  "&:hover": {
    backgroundColor: "#33BBE8",
  },

  // Style for the icon animation
  "& .chevron-icon": {
    position: "absolute",
    right: "30px", // Icon starts off-screen to the right
    opacity: 0,
    transition: "all 0.5s ease",
  },

  // Hover effect animates icon from left-to-right
  "&:hover .chevron-icon": {
    right: "0px", // Moves smoothly into view on hover
    opacity: 1,
  },
}));

function ProductCard(props) {
  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Fills the viewport height
        backgroundColor: "#0f0f0f !important",
        color: "#fff",
        fontFamily: "Open Sans, Arial, sans-serif",
      }}
    >
      {/* Main content area */}
      <Box sx={{ flex: 1, padding: 2, paddingTop: "50px" }}>
        {/* Image and text container */}
        <Box sx={{ maxWidth: "1240px", width: "82%", margin: "0 auto" }}>
          <Grid container spacing={6}>
            {/* Image Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "end" } }}>
                <Box
                  component="img"
                  src={props.cardImg}
                  alt="Product Image"
                  sx={{ width: "90%", marginTop: 9 }}
                />
              </Box>
            </Grid>

            {/* Text Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ padding: 2 }}>
                <Typography
                  variant="h2"
                  component="h2"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    fontSize: "35px",
                    textTransform: "uppercase",
                  }}
                >
                  {props.titleHead}
                </Typography>
                <Typography
                  variant="h2"
                  component="h2"
                  sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "14px" }}
                >
                  {props.titleSubHead}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    marginTop: 3,
                    fontSize: "16px",
                  }}
                >
                  {props.detailHead}
                </Typography>
                <Box sx={{ borderBottom: "1px solid #fff", marginTop: 4 }}></Box>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: 2,
                    lineHeight: 1.6,
                    marginTop: 4,
                    fontSize: "16px",
                  }}
                >
                  {props.detailBody}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: 0,
                    lineHeight: 1.6,
                    marginTop: 0,
                    fontSize: "16px",
                  }}
                >
                  {props.detailBodyBr}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    lineHeight: 1.6,
                    marginTop: 2,
                    fontSize: "16px",
                  }}
                >
                  {props.detailBody2}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  sx={{ marginBottom: 2, marginTop: 2, fontSize: "16px" }}
                >
                  {props.detailBody3}
                </Typography>

                <Box sx={{ textAlign: "left" }}>
                  <Box sx={{ borderBottom: "1px solid #fff", marginTop: 4 }}></Box>
                  <Link to={props.btnLink}>
                    <StyledButton
                      sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
                    >
                      {props.ProductCardBtn}
                      <ChevronRightIcon className="chevron-icon" />
                    </StyledButton>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* Footer (or additional space) can be added here if needed */}
    </Box>
  );
}

export default ProductCard;
