import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/vietnamese_baguette.png";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganB() {
  useMetaTracking("ViewContent", {
    content_name: "Vietnamese Baguette",
    content_category: "Vegan Menu",
    content_ids: ["vietnamese_baguette"],
    content_type: "vegan_vietnamese_pastries",
    value: 2.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vietnamese Baguette | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="A unique blend of wheat and rice flour creates this light and airy Vietnamese baguette, perfect for holding all your favorite fillings. With a crisp, golden crust and a soft, delicate crumb, it’s the ideal base for a traditional Bánh Mì sandwich or any creative variation."
        />
        <meta
          property="og:title"
          content="Vietnamese Baguette | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="A unique blend of wheat and rice flour that creates this light and airy Vietnamese baguette, perfect for holding all your favorite fillings or by itself!"
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="VIETNAMESE BAGUETTE"
          titleSubHead="$2.00 each"
          detailBody="A unique blend of wheat and rice flour creates this light and airy Vietnamese baguette, perfect for holding all your favorite fillings. With a crisp, golden crust and a soft, delicate crumb, it’s the ideal base for a traditional Bánh Mì sandwich or any creative variation."
          detailBodyBr="Contains: wheat"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganB;