import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/Menu/MenuCArd20.png";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganHNC() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Ham & Cheese Croissant",
    content_category: "Vegan Menu",
    content_ids: ["vegan_ham_cheese_croissant"],
    content_type: "vegan_pastries",
    value: 4.25,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Ham & Cheese Croissant | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Flaky, buttery, and packed with bold, savory flavors. Our Vegan Ham & Cheese Croissant brings together a perfectly crisp, golden pastry that's loaded with 3 types of vegan meats & vegan cheese. Each bite delivers the perfect balance of crunch and melt-in-your-mouth satisfaction."
        />
        <meta
          property="og:title"
          content="Vegan Ham & Cheese Croissant | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Flaky, buttery, and packed with bold, savory flavors. Our Vegan Ham & Cheese Croissant brings together a perfectly crisp, golden pastry that's loaded with 3 types of vegan meats & vegan cheese. Each bite delivers the perfect balance of crunch and melt-in-your-mouth satisfaction."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>

      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Ham & Cheese Croissant"
          titleSubHead="$4.25 each"
          detailBody="Flaky, buttery, and packed with bold, savory flavors. Our Vegan Ham & Cheese Croissant brings together a perfectly crisp, golden pastry that's loaded with 3 types of vegan meats & vegan cheese. Each bite delivers the perfect balance of crunch and melt-in-your-mouth satisfaction."
          detailBodyBr="Contains: wheat, soy, sesame"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganHNC;