import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/Menu/MenuCArd39.png";

function PandanMungBean() {
  useEffect(() => {
    // Set page title for SEO
    document.title = "Pandan Mung Bean | Đông Hưng Viên Bakery";

    // Meta Pixel ViewContent tracking
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        content_name: "Pandan Mung Bean",
        content_category: "Vegan Menu",
        content_ids: ["pandan_mung_bean"],
        content_type: "product",
        value: 5.50,
        currency: "USD",
      });
    }
  }, []);

  return (
    <>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="PANDAN MUNG BEAN"
          titleSubHead="$5.50 each"
          detailBody="Taste the richness of vegan Vietnamese coffee made with creamy condensed oat milk from Nature's Charm. Bold and sweet, this indulgent beverage delivers the perfect balance of smoothness and strength, giving you the ultimate dairy-free coffee experience to kick start your day."
          detailBodyBr="Contains: Oat Milk"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default PandanMungBean;
