import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd12.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganGrilledChicken() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Grilled Chicken",
    content_category: "Vegan Menu",
    content_ids: ["vegan_grilled_chicken"],
    content_type: "vegan_banh_mi",
    value: 9.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Grilled Chicken | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Tender vegan grilled chicken with a rich and smoky glaze. Our vegan grilled chicken bánh mì features a savory blend of soy tofu & seitan, marinated in aromatic ingredients for an unforgettable taste."
        />
        <meta
          property="og:title"
          content="Vegan Grilled Chicken | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Tender vegan grilled chicken with a rich and smoky glaze. Our vegan grilled chicken bánh mì features a savory blend of soy tofu & seitan, marinated in aromatic ingredients for an unforgettable taste."
        />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Grilled Chicken"
          titleSubHead="$9.00 each"
          detailBody="Tender with a rich and smoky glaze."
          detailBodyBr="Our vegan grilled chicken bánh mì is made from a savory blend of soy tofu & seitan, marinated in coconut water, soy sauce, sesame oil, lemongrass & mushroom powder for that aromatic & umami-packed satisfaction."
          detailBody3="Contains: wheat, soy, sesame"
          detailBody4="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganGrilledChicken;