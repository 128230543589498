import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/vegan_vietnamese_steamed_bun.png";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function SteamedBun() {
  useMetaTracking("ViewContent", {
    content_name: "Steamed Bun",
    content_category: "Regular Menu",
    content_ids: ["steamed_bun"],
    content_type: "vietnamese_pastries",
    value: 3.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Steamed Bun | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Soft, pillowy, and packed with flavor—this freshly Vietnamese steamed bun is filled with savory pork, egg & a hint of aromatics. A warm, comforting bite that’s both hearty and satisfying."
        />
        <meta property="og:title" content="Steamed Bun | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Soft, pillowy, and packed with flavor—this freshly Vietnamese steamed bun is filled with savory pork, egg & a hint of aromatics. A warm, comforting bite that’s both hearty and satisfying."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="STEAMED BUN"
          titleSubHead="$3.00 each"
          detailBody="Soft, pillowy, and packed with flavor—this freshly Vietnamese steamed bun is filled with savory pork, egg & a hint of aromatics. A warm, comforting bite that’s both hearty and satisfying."
          detailBodyBr="Contains: wheat, soy, egg, almond"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default SteamedBun;