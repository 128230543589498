import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/1200x900_soft_mini_buns.png";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function SoftMiniBuns() {
  useMetaTracking("ViewContent", {
    content_name: "Soft Mini Buns",
    content_category: "Regular Menu",
    content_ids: ["soft_mini_buns"],
    content_type: "vietnamese_pastries",
    value: 4.00,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Soft Mini Buns (Includes Egg) | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Soft, tender, and delicately layered, these mini buns are crafted with a slightly chewy dough that creates a melt-in-your-mouth experience. Twisted into a beautiful spiral shape, each one is filled with rich, flavorful fillings like coconut, taro, mung bean, or durian."
        />
        <meta property="og:title" content="Soft Mini Buns (Includes Egg) | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Soft, tender, and delicately layered, these mini buns are crafted with a slightly chewy dough that creates a melt-in-your-mouth experience. Twisted into a beautiful spiral shape, each one is filled with rich, flavorful fillings like coconut, taro, mung bean, or durian."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="SOFT MINI BUNS (Includes Egg)"
          titleSubHead="$4.00 each"
          detailBody="Soft, tender, and delicately layered, these mini buns are crafted with a slightly chewy dough that creates a melt-in-your-mouth experience. Twisted into a beautiful spiral shape, each one is filled with rich, flavorful fillings like coconut, taro, mung bean, or durian."
          detailBodyBr="Contains: wheat, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default SoftMiniBuns;