import React from "react";
import CardImg from "./../Assets/Menu/MenuCArd5.png";
import Footer from "../Components/Footer/Footer";
import ProductCard from "../Components/ProductCard/ProductCard";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function GrilledChicken() {
  useMetaTracking("ViewContent", {
    content_name: "Grilled Chicken",
    content_category: "Regular Menu",
    content_ids: ["grilled_chicken"],
    content_type: "bánh mì",
    value: 8.0,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Grilled Chicken | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Juicy, marinated chicken grilled to perfection, layered inside a crispy baguette. Smoky, tender, and packed with bold, savory flavors in every bite."
        />
        <meta property="og:title" content="Grilled Chicken | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Juicy, marinated chicken grilled to perfection, layered inside a crispy baguette. Smoky, tender, and packed with bold, savory flavors in every bite."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <ProductCard
          cardImg={CardImg}
          titleHead="Grilled Chicken"
          titleSubHead="$8.00 each"
          detailBodyBr="Juicy, marinated chicken grilled to perfection, layered inside a crispy baguette. Smoky, tender, and packed with bold, savory flavors in every bite."
          detailBody3="Contains: wheat, soy, egg"
          ProductCardBtn="View Full Regular Menu"
          btnLink="/regular-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default GrilledChicken;