import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../Components/Footer/Footer";
import MenuCard from "../Components/MenuCards/MenuCards";
import { BanhMiItems } from "../utils/contants";
import useMetaTracking from "../hooks/useMetaTracking";

function BanhMi() {
  const location = useLocation();
  const navigate = useNavigate();

  useMetaTracking("ViewContent", {
    content_name: "Bánh Mì Page",
    content_category: "Regular Menu",
  });

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleCardClick = (item) => {
    navigate(item.link);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Bánh Mì | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Discover our authentic Bánh Mì featuring fresh ingredients and classic Vietnamese flavors. Enjoy our menu and savor a delicious twist on a traditional favorite."
        />
        <meta property="og:title" content="Bánh Mì | Đông Hưng Viên Bakery" />
        <meta
          property="og:description"
          content="Enjoy our authentic Bánh Mì featuring fresh ingredients and classic Vietnamese flavors."
        />
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "#000",
          py: 10,
        }}
      >
        <Box sx={{ maxWidth: "1080px", width: "90%" }}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.2em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "35px",
                    fontFamily:
                      "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  BÁNH MÌ <br /> SANDWICHES
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.7em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  Enjoy our Bánh Mì with fresh ingredients and classic Vietnamese
                  flavors.
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    mb: 3,
                    lineHeight: "1.7em",
                    px: 2,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  Each sandwich is layered with your choice of savory meats, rich
                  pâté, creamy Vietnamese mayo (bơ), pickled daikon and carrots,
                  crisp cucumber slices, fresh cilantro, jalapeños, and a drizzle
                  of soy sauce.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Grid container spacing={4}>
                  {BanhMiItems.map((item, index) => (
                    <Grid
                      item
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                      key={index}
                    >
                      <MenuCard
                        img={item.img}
                        title={item.title}
                        subTitle={item.subTitle}
                        onAddToCart={() => handleCardClick(item)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </HelmetProvider>
  );
}

export default BanhMi;