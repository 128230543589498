import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/vegan_savory_empanada.png";
import { Helmet } from "react-helmet";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganSavoryEmpanada() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Savory Empanada",
    content_category: "Vegan Menu",
    content_ids: ["vegan_savory_empanada"],
    content_type: "vegan_vietnamese_pastries",
    value: 3.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Savory Empanada | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="A crispy, golden pastry filled with a savory blend of tofu, mushrooms, carrots, and glass noodles. Light and flaky on the outside, rich and flavorful on the inside—perfect for a satisfying handheld snack."
        />
        <meta
          property="og:title"
          content="Vegan Savory Empanada | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="A crispy, golden pastry filled with a savory blend of tofu, mushrooms, carrots, and glass noodles. Light and flaky on the outside, rich and flavorful on the inside—perfect for a satisfying handheld snack."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>

      <Box sx={{ pt: 10, background: "#0f0f0f" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="Vegan Savory Empanada"
          titleSubHead="$3.50 each"
          detailBody="A crispy, golden pastry filled with a savory blend of tofu, mushrooms, carrots, and glass noodles. Light and flaky on the outside, rich and flavorful on the inside—perfect for a satisfying handheld snack."
          detailBodyBr="Contains: wheat, soy, sesame, almond"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganSavoryEmpanada;