import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import ProductCard from "../Components/ProductCard/ProductCard";
import Footer from "../Components/Footer/Footer";
import { Box } from "@mui/material";
import CardImg from "../Assets/Menu/MenuCArd39.png";
import { Helmet } from "react-helmet-async";
import useMetaTracking from "../hooks/useMetaTracking";

function VeganCoffee() {
  useMetaTracking("ViewContent", {
    content_name: "Vegan Vietnamese Coffee",
    content_category: "Vegan Menu",
    content_ids: ["vegan_vietnamese_coffee"],
    content_type: "vegan_vietnamese_drinks",
    value: 5.50,
    currency: "USD",
  });

  return (
    <>
      <Helmet>
        <title>Vegan Vietnamese Coffee | Đông Hưng Viên Bakery</title>
        <meta
          name="description"
          content="Taste the richness of vegan Vietnamese coffee made with creamy condensed oat milk from Nature's Charm. Bold and sweet, this indulgent beverage delivers the perfect balance of smoothness and strength, giving you the ultimate dairy-free coffee experience to kick start your day."
        />
        <meta
          property="og:title"
          content="Vegan Vietnamese Coffee | Đông Hưng Viên Bakery"
        />
        <meta
          property="og:description"
          content="Taste the richness of vegan Vietnamese coffee made with creamy condensed oat milk from Nature's Charm. Bold and sweet, this indulgent beverage delivers the perfect balance of smoothness and strength, giving you the ultimate dairy-free coffee experience to kick start your day."
        />
        <meta property="og:image" content={CardImg} />
      </Helmet>
      <Box sx={{ pt: 10, background: "#0f0f0f", color: "#fff" }}>
        <Navbar />
        <ProductCard
          cardImg={CardImg}
          titleHead="VEGAN VIETNAMESE COFFEE"
          titleSubHead="$5.50 each"
          detailBody="Taste the richness of vegan Vietnamese coffee made with creamy condensed oat milk from Nature's Charm. Bold and sweet, this indulgent beverage delivers the perfect balance of smoothness and strength, giving you the ultimate dairy-free coffee experience to kick start your day."
          detailBodyBr="Contains: Oat Milk"
          detailBody3="Vegan"
          ProductCardBtn="View Full Vegan Menu"
          btnLink="/vegan-menu"
        />
        <Footer />
      </Box>
    </>
  );
}

export default VeganCoffee;